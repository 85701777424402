const commonData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
            treeProps: {
                children: 'Children',
                hasChildren: 'hasChildren'
            }
        },
        columns: {
            // 选择、序号
            columnsOperate: [
                // {
                //     isShow: true,
                //     type: 'selection',
                //     width: 35
                // }, 
                {
                    isShow: true,
                    label: '序号',
                    type: 'index',
                    width: 50
                }
            ],
            // 数据列
            columnsData: [

            ],
            // 操作列
            columnsBtn: {
                buttonData: [{
                    isShow: true,
                    showMethod: 'showSeeBtn',
                    btnType: 'icon',
                    operateType: 'columnSee',
                    title: '查看',
                    icon: 'el-icon-view',
                    backColor: 'transparent',
                    color: '#20a0ff'
                },

                {
                    isShow: true,
                    showMethod: 'showEditBtn',
                    btnType: 'icon',
                    operateType: 'columnEdit',
                    title: '编辑',
                    icon: 'el-icon-edit',
                    backColor: 'transparent',
                    color: 'green'
                },
                {
                    isShow: true,
                    showMethod: 'showEditBtnAdmin',
                    btnType: 'icon',
                    operateType: 'columnEditAdmin',
                    title: '编辑',
                    icon: 'el-icon-edit',
                    backColor: 'transparent',
                    color: 'green'
                },
                // 1201
                {
                    isShow: true,
                    showMethod: 'showWarnBtn',
                    btnType: 'img',
                    operateType: 'toWarn',
                    title: '转为预警',
                    src: 'working_desk/monitor_data/warn.png',
                    backColor: 'transparent',
                    color: 'green'
                },
                {
                    isShow: true,
                    showMethod: 'showDelBtn',
                    btnType: 'icon',
                    operateType: 'columnDelete',
                    title: '删除',
                    icon: 'el-icon-delete',
                    backColor: 'transparent',
                    color: 'red'
                },
                // 191
                {
                    isShow: true,
                    showMethod: 'showYqOperateBtn',
                    btnType: 'icon',
                    operateType: 'columnYqOperate',
                    title: '处置',
                    icon: 'el-icon-edit',
                    backColor: 'transparent',
                    color: 'green'
                },
                // 1205 start
                {
                    isShow: true,
                    showMethod: 'showAbnormal',
                    btnType: 'img',
                    operateType: 'toAbnormal',
                    title: '转为异常',
                    src: 'working_desk/monitor_data/warn.png',
                    backColor: 'transparent',
                    color: 'green'
                },
                {
                    isShow: true,
                    showMethod: 'showReply',
                    btnType: 'icon',
                    operateType: 'replyMsg',
                    title: '回复',
                    icon: 'el-icon-edit-outline',
                    backColor: 'transparent',
                    color: 'green'
                },
                // 1205 end
                {
                    isShow: true,
                    showMethod: 'showColumnAdd',
                    btnType: 'icon',
                    operateType: 'columnAdd',
                    title: '录入',
                    icon: 'el-icon-document-add',
                    backColor: 'transparent',
                    color: '#00a7bd'
                },
                {
                    isShow: true,
                    showMethod: 'showColumnAdd',
                    btnType: 'icon',
                    operateType: 'columnAdd',
                    title: '录入',
                    icon: 'el-icon-document-add',
                    backColor: 'transparent',
                    color: '#00a7bd'
                },
                ]
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snCount: {
        eachtotal: [20, 50, 100],
        defaultTiaoshu: 20
    },
    snSearch: {
        selectData: [],
        searchBtn: {
            buttonData: [{
                btnType: 'button',
                operateType: 'searchBtn',
                name: '查询',
                round: true,
                backColor: '#2a63d5',
                color: '#fff'
            }]
        }
    },
    snButton: {
        buttonData: []
    }
};
export { commonData };