<template>
    <div class="mainPart">
        <el-form
            ref="formdata"
            class="demo-form-inline form_row"
            :inline="true"
            :model="formdata"
            size="small"
            label-width="100px"
            :rules="rules"
        >
            <div class="flex mainCtn">
                <div class="mainItem">
                    <el-form-item label="预警等级" prop="Yjdj">
                        <el-select
                            v-model="formdata.Yjdj"
                            placeholder="请选择预警等级"
                            filterable
                            style="width: 300px"
                        >
                            <el-option
                                v-for="(item, index) in yjdjList"
                                :key="index"
                                :label="item.Name"
                                :value="item.Code"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分类" prop="Fl">
                        <el-select
                            v-model="formdata.Fl"
                            placeholder="请选择分类"
                            filterable
                            style="width: 300px"
                            disabled
                        >
                            <el-option
                                v-for="(item, index) in flList"
                                :key="index"
                                :label="item.YJDXMC"
                                :value="item.ID"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="预警事件" prop="Yjsj">
                        <el-input
                            v-model="formdata.Yjsj"
                            placeholder="请输入预警事件"
                            :maxlength="50"
                            type="textarea"
                            style="width: 300px"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="发生预警时间" prop="Fsyjsj">
                        <el-date-picker
                            v-model="formdata.Fsyjsj"
                            type="date"
                            placeholder="选择日期"
                            style="width: 300px"
                            disabled
                        >
                        </el-date-picker>
                    </el-form-item>
                </div>
                <div class="mainItem">
                    <el-form-item label="经纬度" prop="">
                        <div class="flex-between" style="width: 300px">
                            <el-input
                                v-model="formdata.ZXD"
                                placeholder="示例：42.357589,116.17958"
                                disabled
                            ></el-input>
                            <el-button @click="showPickup = true"
                                >地图选点</el-button
                            >
                        </div>
                    </el-form-item>
                    <!-- <el-form-item label="评估" prop="">
                        <el-input
                            style="width: 300px"
                            v-model="formdata.Cjsjvalue"
                            disabled
                        ></el-input>
                    </el-form-item> -->
                    <el-form-item label="评估" prop="Pg">
                        <el-select
                            v-model="formdata.Pg"
                            placeholder="请选择评估"
                            filterable
                            style="width: 300px"
                            disabled
                        >
                            <el-option
                                v-for="(item, index) in pgList"
                                :key="index"
                                :label="item.LABEL"
                                :value="item.CODE"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="预警说明" prop="Yjsm">
                        <el-input
                            v-model="formdata.Yjsm"
                            placeholder="请输入"
                            :maxlength="50"
                            type="textarea"
                            style="width: 300px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="预警发生位置" prop="Yjfswz">
                        <el-input
                            v-model="formdata.Yjfswz"
                            placeholder="请输入"
                            :maxlength="50"
                            type="textarea"
                            style="width: 300px"
                        ></el-input>
                    </el-form-item>
                </div>
            </div>

            <el-form-item label="异常照片" prop="">
                <div class="imgPart scrollbar">
                    <SnFileDialog :fileData="fileData"> </SnFileDialog>
                </div>
            </el-form-item>
            <div class="flex-end">
                <el-button
                    type="primary"
                    class="submitBtn"
                    round
                    @click="onSubmit()"
                    >异常转预警</el-button
                >
                <el-button
                    type="danger"
                    class="cancelBtn"
                    round
                    @click="cancel()"
                    >取消</el-button
                >
            </div>
        </el-form>
        <coordinatePickup
            :editState="isEdit"
            :showPickupP.sync="showPickup"
            :coordinate.sync="formdata.ZXD"
        ></coordinatePickup>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import coordinatePickup from "@comp/map/coordinate_pickup";
export default {
    name: "",
    data() {
        return {
            showPickup: false,
            coordinate: null,
            formdata: {
                ID: "",
                ZXD: "",
                Yjsj: "", //预警事件【用户填写】
                Yjdj: "", //预警等级【用户选择】
                Fsyjsj: "", // 发生预警时间（取异常记录中的CJSJ）
                Yjsm: "", //预警说明（取异常记录中的SJMS【且可让用户修改】）
                Yjfswz: "", //预警发生位置（取异常记录中的YCFSWZ【且可让用户修改】）
                Jd: "", // 经度（取异常记录中JD）
                Wd: "", // 纬度（取异常记录中WD）
                Fzrid: "", //取异常记录中CJRID）
                Ycysbm: "", //（取异常记录中YCYSBM）
                Yczc: "", //（取异常记录中YCYSZC）
                Cjsjvalue: "", //（取异常记录中PG_DESC）
                Pg: "", //（取异常记录中PG_DESC）
                Fl: "", //分类
            },
            yjdjList: [], //预警等级列表
            pgList: [],
            flList: [], //分类
            rules: {
                Yjdj: [
                    {
                        required: true,
                        message: "请选择预警等级",
                        trigger: "change",
                    },
                ],
            },
            fileData: {
                mp3List: [],
                mp4List: [],
                imgList: [],
                othersPart: {
                    key: "",
                    name: "",
                    list: [],
                },
            },
        };
    },
    components: {
        coordinatePickup,
    },
    computed: {},
    props: {
        dialogInfo: Object,
    },
    watch: {
        dialogInfo: {
            immediate: true,
            handler() {
                this.formdata.ID = this.dialogInfo.ID;
                this.formdata.Yjsj = ""; //预警事件【用户填写】
                this.formdata.Fsyjsj = this.dialogInfo.CJSJ; //发生预警时间（取异常记录中的CJSJ）
                this.formdata.Yjsm = this.dialogInfo.SJMS; //预警说明（取异常记录中的SJMS【且可让用户修改】）
                this.formdata.Yjfswz = this.dialogInfo.YCFSWZ; //预警发生位置（取异常记录中的YCFSWZ【且可让用户修改】）
                this.formdata.Jd = this.dialogInfo.JD;
                this.formdata.Wd = this.dialogInfo.WD;
                this.formdata.ZXD = `${this.dialogInfo.JD},${this.dialogInfo.WD}`;

                this.formdata.Fzrid = this.dialogInfo.CJRID; //取异常记录中CJRID）
                this.formdata.Ycysbm = this.dialogInfo.YCYSBM; //（取异常记录中YCYSBM）
                this.formdata.Yczc = this.dialogInfo.YCYSZC; //（取异常记录中YCYSZC）
                this.formdata.Cjsjvalue = this.dialogInfo.PG_DESC; //（取异常记录中PG_DESC）
                this.formdata.Pg = this.dialogInfo.PG; //（取异常记录中PG_DESC）

                this.fileData.mp3List = [];
                this.fileData.mp4List = [];
                this.fileData.imgList = [];
                this.dialogInfo.PIC.map((item) => {
                    let dw = item.CCLJ.substring(
                        item.CCLJ.lastIndexOf(".") + 1
                    );
                    switch (dw) {
                        case "mp3":
                            this.fileData.mp3List.push(item.CCLJ);
                            break;
                        case "mp4":
                            this.fileData.mp4List.push(item.CCLJ);
                            break;
                        case "jpg":
                        case "png":
                        case "jpeg":
                        case "gif":
                        case "tiff":
                            this.fileData.imgList.push(item.CCLJ);
                            break;
                        default:
                            this.fileData.othersPart.list.push(item);
                            break;
                    }
                });
            },
        },
    },
    mounted() {
        this.getEnums();
        this.getYjxList();
        this.getYjdjList();
    },
    methods: {
        ...mapActions([
            "YcjlToYj",
            "getAllEnumInfobytableID",
            "GetYjxList",
            "GetEnumItem",
        ]),
        async onSubmit() {
            let info = {
                Yjsj: this.formdata.Yjsj, //预警事件【用户填写】
                Yjdj: this.formdata.Yjdj, //预警等级【用户选择】
                Fsyjsj: this.formdata.Fsyjsj, // 发生预警事件（取异常记录中的CJSJ）
                Yjsm: this.formdata.Yjsm, //预警说明（取异常记录中的SJMS【且可让用户修改】）
                Yjfswz: this.formdata.Yjfswz, //预警发生位置（取异常记录中的YCFSWZ【且可让用户修改】）
                Jd: this.formdata.Jd, // 经度（取异常记录中JD）
                Wd: this.formdata.Wd, // 纬度（取异常记录中WD）
                Fzrid: this.formdata.Fzrid, //取异常记录中CJRID）
                Ycysbm: this.formdata.Ycysbm, //（取异常记录中YCYSBM）
                Yczc: this.formdata.Yczc, //（取异常记录中YCYSZC）
                Cjsjvalue: this.formdata.Cjsjvalue, //（取异常记录中PG_DESC）
            };
            let res = await this.YcjlToYj({
                ycid: this.formdata.ID, //异常记录ID
                info: JSON.stringify(info), //预警信息
            });
            if (res.IsSuccess) {
                this.common.showMsg("保存成功", "success");
                this.$emit("closeDialog");
            }
        },
        cancel() {
            this.$emit("closeDialog");
        },
        async getEnums() {
            const res = await this.getAllEnumInfobytableID({
                bid: 1201,
            });
            res.map((item) => {
                if (item.COLUMNID == "PG") {
                    this.pgList = item.dataItems;
                }
            });
        },
        // 获取预警核实-类型列表
        async getYjxList() {
            this.yjhslxList = [];
            let result = await this.GetYjxList();
            if (result.length) {
                this.flList = result;
                this.formdata.Fl = this.flList[0].ID;
            }
        },
        // 获取预警等级
        async getYjdjList() {
            let result = await this.GetEnumItem({
                Enumid: "212cb2aa-748a-11ea-bd8d-000c2977b7fd",
            });
            this.yjdjList = result;
        },
    },
};
</script>
<style scoped lang="scss">
.mainPart {
    width: 100%;
    .mainCtn {
        width: 100%;
        .mainItem {
            width: 50%;
        }
    }
    .imgPart {
        width: 760px;
        max-height: 300px;
    }
}
</style>
