<template>
    <div class="pic-compare">
        <sn-search
            ref="snSearch"
            class="sn-search clearfix"
            :snSearch.sync="snSearch"
            @handleChange="handleChange"
        ></sn-search>
        <div class="content flex">
            <div class="compare flex">
                <div class="left">
                    <el-row>
                        <el-col :span="24" class="desc title">基准图:</el-col>
                    </el-row>
                    <div class="img-box">
                        <div @click="handleClickPic(jztUrl)">
                            <el-image
                                v-if="jztUrl"
                                style="
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    width: 100%;
                                "
                                :src="jztUrl"
                                fit="cover"
                            ></el-image>
                            <span v-else>暂无图片</span>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <el-row>
                        <el-col :span="24" class="desc title">对比图:</el-col>
                    </el-row>
                    <div class="img-box">
                        <div @click="handleClickPic(curDbt.imgList)">
                            <el-image
                                v-if="curDbt.dbtUrl"
                                style="
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    width: 100%;
                                "
                                :src="curDbt.dbtUrl"
                                fit="cover"
                            ></el-image>
                            <span v-else>暂无图片</span>
                        </div>
                    </div>
                    <div class="info">
                        <el-row v-for="item in infoList" :key="item.label">
                            <el-col :span="7" class="label desc"
                                >{{ item.label }}:</el-col
                            >
                            <el-col :span="16" :offset="1">{{
                                item.value
                            }}</el-col>
                        </el-row>
                    </div>
                </div>
            </div>
            <div class="time">
                <el-button
                    :style="{ opacity: timeList.length > 1 ? 1 : 0 }"
                    style="margin: 11px"
                    type="primary"
                    :icon="iconBtn"
                    size="mini"
                    @click="handleClickIcon"
                    >{{ iconText }}</el-button
                >
                <ul class="scrollbar-hidden">
                    <li
                        v-for="item in timeList"
                        :key="item.CJSJ"
                        :class="{ active: item.CJSJ === curDbt.CJSJ }"
                        @click="handleClick(item)"
                    >
                        <span class="dot"></span>
                        <span class="text">{{ item.CJSJ | simple }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <ViewerAlert v-if="false" ref="myImgList"
                     :imgList="imgList"
        ></ViewerAlert> -->
    </div>
</template>

<script>
// import ViewerAlert from "@views/big_screen/comp/ViewerAlert.vue"; // 大图查看
import monitorToBasicDataMap from "../table_config/monitor_to_basic";
import { mapActions } from "vuex";
export default {
    components: {
        // ViewerAlert
    },
    filters: {
        simple(v) {
            return v && v.substr(0, 10);
        },
    },
    props: {
        itemCode: {
            default: "",
        },
        picTable: {
            default: false,
        },
    },
    data() {
        return {
            timer: null,
            dbtIndex: 0,
            iconBtn: "el-icon-video-pause",
            iconText: "暂停",
            imgList: [],
            dbtUrl: "",
            dbtMc: "",
            curDbt: {}, // 当前选中对比图
            snSearch: {
                radioData: [],
                inputData: [],
                treeselectData: [],
                selectData: [
                    {
                        id: "mc",
                        label: "",
                        clearable: true,
                        placeholder: "",
                        list: [],
                        optionLabel: "NAME",
                        optionValue: "ID",
                        value: "mc",
                        operateType: "search",
                        isShow: true,
                    },
                    {
                        id: "jcd",
                        label: "监测点",
                        selectValue: null,
                        clearable: true,
                        placeholder: "请选择监测点",
                        list: [],
                        optionLabel: "NAME",
                        optionValue: "ID",
                        value: "jcd",
                        operateType: "search",
                        isShow: true,
                    },
                ],
                dateData: [
                    {
                        id: "cjsj",
                        isShow: true,
                        type: "daterange",
                        rangeSeparator: "至",
                        startPlaceholder: "开始日期",
                        endPlaceholder: "结束日期",
                        operateType: "search",
                        value: "time",
                    },
                ],
                buttonData: [],
                searchBtn: {
                    buttonData: [
                        {
                            btnType: "button",
                            operateType: "searchBtn",
                            name: "查询",
                            round: true,
                            backColor: "#2a63d5",
                            color: "#fff",
                        },
                        {
                            btnType: "button",
                            operateType: "returnBtn",
                            name: "返回",
                            round: true,
                            backColor: "#ff9066",
                            color: "#fff",
                        },
                    ],
                },
            },
            payload: {
                mc: "",
                jcd: "",
                startTime: "",
                endTime: "",
            },
            timeList: [],
            mapKeyToLabel: {
                CJSJ: "采集时间",
                SM: "说明",
                CJRMC: "采集人名称",
                JCWZMC: "监测位置名称",
                XTSFFSBH_DESC: "形态是否发生变化",
                YCYSBCZT_DESC: "遗产要素保存状态",
                QKSM_DESC: "情况说明",
                PG_DESC: "评估说明",
            },
        };
    },
    computed: {
        infoList() {
            let res = [];
            if (this.curDbt && typeof this.curDbt === "object") {
                Object.entries(this.curDbt).forEach(([k, v]) => {
                    if (this.mapKeyToLabel[k]) {
                        res.push({
                            label: this.mapKeyToLabel[k],
                            value: v || "暂无数据",
                        });
                    }
                });
            }
            return res;
        },
        jztUrl() {
            const jcdItem = this.snSearch.selectData
                .find((ele) => ele.id === "jcd")
                .list.find((ele) => ele.ID === this.payload.jcd);
            let res = "";
            if (jcdItem) {
                res = jcdItem.jztUrl;
            }
            return res;
        },
    },
    async created() {
        await this.addFirstSelect();
        await this.addJcdSelect();
        await this.addDateRangeSelect();
    },
    mounted() {
        setTimeout(() => {
            this.getFirst();
        }, 500);
    },
    destroyed() {
        clearInterval(this.timer);
    },
    methods: {
        ...mapActions([
            "GetYcysList",
            "GetMobileCjsj",
            "GetJcdList",
            "getMonitorDataList",
            "getBasicList",
        ]),
        autoShow() {
            if (this.timeList.length > 1) {
                clearInterval(this.timer);
                const total = this.timeList.length;
                this.timer = setInterval(() => {
                    this.dbtIndex = (this.dbtIndex + 1) % total;
                    this.handleClick(this.timeList[this.dbtIndex]);
                }, 2000);
            }
        },
        handleClickIcon() {
            const iconBtn1 = "el-icon-video-pause";
            const iconBtn2 = "el-icon-video-play";
            if (this.iconBtn === iconBtn1) {
                this.iconBtn = iconBtn2;
                this.iconText = "轮播";
                clearInterval(this.timer);
            } else {
                this.iconBtn = iconBtn1;
                this.iconText = "暂停";
                this.autoShow();
            }
        },
        handleClickPic(src) {
            if (Array.isArray(src)) {
                this.imgList = src;
            } else {
                this.imgList = [
                    {
                        alt: ``,
                        src,
                        thumbnail: src,
                    },
                ];
            }
            this.$refs.myImgList.showViewer(0);
        },
        async getFirst() {
            const find = this.snSearch.selectData.find((ele) => ele.id === "mc")
                .list[0];
            const mc = find && find.ID;
            this.payload.mc = mc;
            this.$refs.snSearch.resetSearchValue("mc", mc);
            await this.addJcdSelect(mc);
            const jcdObj = this.snSearch.selectData.find(
                (ele) => ele.id === "jcd"
            ).list[0];
            this.payload.jcd = jcdObj && jcdObj.ID;
            this.$refs.snSearch.resetSearchValue("jcd", this.payload.jcd);
            await this.getData();
        },
        handleClick(item) {
            this.curDbt = item;
        },
        async handleChange(type, value) {
            console.log("type:", type, "value:", value);
            if (type === "searchBtn") {
                const flag = await this.getData();
                if (flag) {
                    this.$message({
                        message: "未选择过滤条件",
                        type: "info",
                    });
                }
                return;
            }
            if (type === "returnBtn") {
                this.$emit("changePicTable", true);
                return;
            }
            const pKey = value[1];
            const pValue = value[0];
            if (pKey === "time") {
                this.payload.startTime = (pValue && pValue[0]) || "";
                this.payload.endTime = (pValue && pValue[1]) || "";
            } else {
                this.payload[pKey] = pValue;
            }
            if (pKey === "mc") {
                await this.addJcdSelect(pValue);
                const jcdObj = this.snSearch.selectData.find(
                    (ele) => ele.id === "jcd"
                ).list[0];
                this.payload.jcd = jcdObj && jcdObj.ID;
                this.payload.startTime = "";
                this.payload.endTime = "";
                this.$refs.snSearch.resetSearchValue("jcd", this.payload.jcd);
                this.$refs.snSearch.resetSearchValue("cjsj", "");
            }
            if (pKey === "jcd") {
                this.payload.startTime = "";
                this.payload.endTime = "";
                this.$refs.snSearch.resetSearchValue("cjsj", "");
            }
            await this.getData();
        },
        async getData() {
            const payload = {
                itemCode: this.itemCode,
                ...this.payload,
            };
            if (!payload.mc || !payload.jcd) {
                this.setTimeList([]);
                return true;
            }
            const res = await this.GetMobileCjsj(payload);
            if (Array.isArray(res)) {
                this.setTimeList(res);
            }
            setTimeout(() => {
                this.autoShow();
            }, 500);
        },
        setTimeList(list) {
            // if (Number(this.itemCode) === 602) {
            this.timeList = list.map((ele) => {
                const dbtUrl = ele && ele.PIC && ele.PIC[0] && ele.PIC[0].CCLJ;
                const temp = ele && ele.PIC && ele.PIC;
                let imgList = [];
                if (temp && temp.length > 0) {
                    imgList = temp.map((ele) => ({
                        alt: ``,
                        src: ele.CCLJ,
                        thumbnail: ele.CCLJ,
                    }));
                }
                return {
                    CJSJ: ele.CJSJ,
                    dbtUrl,
                    ...ele,
                    imgList,
                };
            });
            // }
            // else {
            //     this.timeList = list.map(ele => {
            //         const dbtUrl = ele && ele.PIC && ele.PIC[0] && ele.PIC[0].CCLJ;
            //         return {
            //             CJSJ: ele.CJSJ,
            //             dbtUrl,
            //             ...ele
            //         };
            //     });
            // }
            // 展示第一张图片
            if (this.timeList.length > 0) {
                this.handleClick(this.timeList[0]);
            } else {
                this.handleClick({});
            }
        },
        async addFirstSelect() {
            if (this.itemCode === "602") {
                this.getYcysData();
            } else {
                this.getGcidList();
            }
        },
        async getYcysData() {
            const data = await this.GetYcysList();
            const res = data && data.ResultValue;
            if (res) {
                const list = res.map(({ BM: ID, MC: NAME }) => ({ ID, NAME }));
                const item = this.snSearch.selectData.find(
                    (ele) => ele.id === "mc"
                );
                if (item) {
                    item.list = list;
                    item.label = "遗产要素";
                    item.placeholder = "请选择遗产要素";
                }
            }
        },
        async getGcidList() {
            const mapItemCodeToItemId = {
                1403: {
                    code: 1402,
                    prop: "JLMC",
                },
                1503: {
                    code: 1501,
                    prop: "GCMC",
                },
                90203: {
                    code: 90201,
                    prop: "XMMC",
                },
            };
            const itemId = mapItemCodeToItemId[this.itemCode].code;
            let result;
            if (monitorToBasicDataMap[itemId]) {
                result = await this.getBasicList({
                    userid: localStorage.userId,
                    ycdid: 11030,
                    itemId: monitorToBasicDataMap[itemId],
                    authority: false,
                    keyWord: "",
                    strwhere: "[]",
                    pageIndex: 1,
                    pageSize: 9999,
                    sort: "",
                    order: "",
                });
            } else {
                result = await this.getMonitorDataList({
                    userid: localStorage.userId,
                    itemId,
                    authority: false,
                    keyword: "",
                    strwhere: "[]",
                    sort: "",
                    sortorder: "",
                    start: "",
                    end: "",
                    pageIndex: 1,
                    pageSize: 9999,
                });
            }
            const res = result.ResultValue.gridData || [];
            const prop = mapItemCodeToItemId[this.itemCode].prop;
            const list = res.map((ele) => ({
                ID: ele.ID,
                NAME: ele[prop] || "暂无名称",
            }));
            const item = this.snSearch.selectData.find(
                (ele) => ele.id === "mc"
            );
            if (item) {
                item.list = list;
                item.label = "工程项目";
                item.placeholder = "请选择工程项目";
            }
        },
        addDateRangeSelect() {
            const item = {
                id: "cjsj",
                isShow: true,
                type: "daterange",
                rangeSeparator: "至",
                startPlaceholder: "开始日期",
                endPlaceholder: "结束日期",
                operateType: "search",
                value: "time",
            };
            const find = this.snSearch.dateData.find(
                (ele) => ele.id === "cjsj"
            );
            if (!find) {
                this.snSearch.dateData.push(item);
            }
        },
        async addJcdSelect(mc = "") {
            if (!mc) {
                return;
            }
            const payload = {
                itemCode: this.itemCode,
                ycysbmOrgcid: mc,
            };
            const res = await this.GetJcdList(payload);
            const item = this.snSearch.selectData.find(
                (ele) => ele.id === "jcd"
            );
            const nameProp = {
                602: "MC",
                1403: "JCWZMC",
                1503: "JCWZMC",
                90203: "JCWZMC",
            }[this.itemCode];
            const list = [];
            if (Array.isArray(res)) {
                res.forEach((ele) => {
                    let jztUrl = "";
                    if (Number(this.itemCode) === 602) {
                        if (ele.PIC && ele.PIC.length > 0) {
                            jztUrl = ele.PIC[0].CCLJ;
                        }
                    } else {
                        jztUrl = ele.JZTLJ;
                    }
                    const temp = {
                        ID: ele.ID,
                        NAME: ele[nameProp] || "暂无名称",
                        jztUrl,
                    };
                    list.push(temp);
                });
            }
            item.list = list;
            item.label = "监测点";
            item.placeholder = "请选择监测点";
        },
    },
};
</script>

<style lang="scss">
.pic-compare {
    input.el-range-input {
        width: 70px;
    }
    span.el-range-separator {
        display: inline-block;
        width: 50px;
    }
    div.el-date-editor.el-range-editor.el-input__inner.time.option.el-date-editor--daterange.el-range-editor--mini {
        width: auto;
    }
    .img-box {
        .el-image {
            height: 100%;
        }
    }
}
</style>
<style scoped lang="scss">
$itemColor: #88c896;
.pic-compare {
    height: calc(100% - 42px);
    .desc {
        font-weight: 700;
        color: #182987;
        font-size: 16px;
    }
    .title {
        margin-bottom: 10px;
        border-bottom: 1px solid #182987;
    }
    .content {
        height: calc(100% - 50px);
        .compare {
            padding: 20px 20px 0px 20px;
            width: calc(100% - 140px);
            max-width: 1050px;
            box-sizing: border-box;
            .left {
            }
            .right {
                margin-left: 10px;
                max-width: 500px;
                .info {
                    margin-top: 20px;
                    div.label {
                        text-align: right;
                        font-size: 14px;
                    }
                }
            }
            .left,
            .right {
                max-width: 500px;
                width: 50%;
                .img-box {
                    width: 100%;
                    padding-top: 60%;
                    position: relative;
                    > div {
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        position: absolute;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
        .time {
            max-height: 350px;
            flex: 0 0 144px;
            height: 100%;
            ul {
                width: 140px;
                margin-bottom: 50px;
                height: calc(100% - 50px);
                li {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    span.dot {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        position: relative;
                        background-color: #ccc;
                        border-radius: 50%;
                        &::before,
                        &::after {
                            display: block;
                            content: "";
                            width: 2px;
                            height: 15px;
                            position: absolute;
                            left: 50%;
                            background: #ccc;
                        }
                        &::before {
                            top: 0;
                            transform: translateY(-100%) translateX(-50%)
                                translateY(-3px);
                        }
                        &::after {
                            bottom: 0;
                            transform: translateY(100%) translateX(-50%)
                                translateY(3px);
                        }
                    }
                    span.text {
                        height: 30px;
                        line-height: 30px;
                        margin-left: 20px;
                        padding: 0 15px;
                        display: flex;
                        align-items: center;
                        background: url("~@image/working_desk/monitor_data/time_label.png")
                            no-repeat left center/contain;
                        position: relative;
                        // border-right:2px solid $itemColor;
                        // border-top:2px solid $itemColor;
                        // border-bottom:2px solid $itemColor;
                        // &::before{
                        //     display: inline-block;
                        //     position: absolute;
                        //     left: 0;
                        //     transform: translateX(-100%);
                        //     content:'';
                        //     height: 16px;
                        //     width: 16px;
                        //     border-right:2px solid transparent;
                        //     border-left:2px solid $itemColor;
                        //     border-top:2px solid $itemColor;
                        //     border-bottom:2px solid transparent;
                        //     box-sizing: border-box;
                        //     transform-origin: center;
                        //     transform: translateX(-8px) rotate(-58deg) skewX(-30deg);
                        // }
                    }
                }
                li.active {
                    span.dot {
                        background-color: $itemColor;
                    }
                    span.text {
                        background: url("~@image/working_desk/monitor_data/time_label_active.png")
                            no-repeat left center/contain;
                        // &::before{
                        //     display: inline-block;
                        //     position: absolute;
                        //     left: 0;
                        //     transform: translateX(-100%);
                        //     content:'';
                        //     height: 0;
                        //     width: 0;
                        //     border-right:10px solid $itemColor;
                        //     border-left:0 solid;
                        //     border-top:15px solid transparent;
                        //     border-bottom:15px solid transparent;
                        // }
                    }
                }
            }
        }
    }
}
</style>