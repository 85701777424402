// 保护展示与环境整治工程记录
const rightTableData = {
    snTable: {
        table: {
            data: [],
            expandRowKeys: [],
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'name',
                label: '文档类型',
            }, {
                prop: 'wdmc',
                label: '附件',
                transList: {
                    '待上传': '待上传',
                    '查看': '查看',
                    '无': '无',
                },
                colorList: {
                    '待上传': '#606266',
                    '查看': '#28ccd9',
                    '无': '#606266',
                },
            }, {
                prop: 'shzt',
                label: '保存状态',
                transList: {
                    0: '未审核', // 未审核 / 提交未审核
                    1: '未提交', // 未提交
                    2: '审核通过', // 审核通过
                    3: '审核不通过', // 审核不通过
                },
                colorList: {
                    0: '#28ccd9',
                    1: '#ff9066',
                    2: '#2ec392',
                    3: '#fa6f1b'
                },
                minWidth: 90
            }],
            // 操作列
            columnsBtn: {
                width: 70,
                buttonData: []
            }
        },
    }
};
export { rightTableData };