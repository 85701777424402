<template>
    <div class="advance-container">
        <div
            v-for="{ FIENAME, COLUMNID, dataItems } in enumList"
            :key="COLUMNID"
        >
            <label
                ><span>{{ FIENAME }}</span
                >:</label
            >
            <Options
                ref="option"
                :dataItems="dataItems"
                @serchdata="serchdata(arguments, COLUMNID)"
            />
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import Options from "./Options";
export default {
    name: "advancesearch",
    components: {
        Options,
    },
    props: {
        /**
         * 表ID，用与查询指定表的枚举即高级查询数据源
         */
        tableId: {
            required: false,
            default: null,
        },
        /**
         * 高级查询数据源，默认为枚举，个别项会有添加或删除
         */
        serchEnumData: {
            required: false,
            default: null,
        },
        includes: {
            default: () => [],
        },
        excludes: {
            default: () => [],
        },
    },
    data() {
        return {
            searchList: [],
            cfDate: null, // 触发时间
            enumList: [
                {
                    FIENAME: "测试",
                    COLUMNID: 1,
                    dataItems: [{ LABEL: "选项一" }, { LABEL: "选项二" }],
                },
            ],
        };
    },
    computed: {},
    watch: {
        tableId: {
            immediate: true,
            handler() {
                if (this.tableId) {
                    this.getEnums();
                }
            },
        },
    },
    created() {},
    updated() {
        setTimeout(() => {
            $(
                "div.advance-container>div>div>div>label.el-checkbox>span.el-checkbox__label"
            ).each(function (ind, ele) {
                const title = $($(this)[0]).text();
                $(ele).attr("title", title);
            });
        }, 1000);
    },
    methods: {
        ...mapActions([
            "getEnumData",
            "setDistributionEnumList",
            "getAllEnumInfobytableID",
        ]),
        async getEnums() {
            let res = await this.getAllEnumInfobytableID({ bid: this.tableId });
            if (res && Array.isArray(res) && res.length > 0) {
                if (this.includes.length > 0) {
                    res = res.filter((ele) =>
                        this.includes.includes(ele.COLUMNID)
                    );
                }
                if (this.excludes.length > 0) {
                    res = res.filter(
                        (ele) => !this.excludes.includes(ele.COLUMNID)
                    );
                }
                const enumList = res;
                // res.forEach((ele, index) => {
                //     const findIndex = enumList.findIndex(item => {
                //         return ele.COLUMNID === item.COLUMNID;
                //     });
                //     if (findIndex < 0) {
                //         enumList.push({
                //             COLUMNID: ele.COLUMNID,
                //             FIENAME: ele.ENUMNAME,
                //             dataItems: [
                //                 {
                //                     CODE: ele.Code,
                //                     LABEL: ele.Name,
                //                 }
                //             ]
                //         });
                //     } else {
                //         enumList[findIndex].dataItems.push({ CODE: ele.Code, LABEL: ele.Name, });
                //     }
                // });
                // this.setDistributionEnumList({ enumList });
                this.enumList = enumList;
                console.log("枚举列表", this.enumList);
            }
        },
        async getEnumDataList() {
            if (this.enumList && this.enumList.length > 0) return;
            if (!this.tableId) return;
            let _tableId = this.tableId;
            let res = await this.getEnumData({ _tableId });
            if (res && Array.isArray(res) && res.length > 0) {
                const enumList = [];
                res.forEach((ele, index) => {
                    const findIndex = enumList.findIndex((item) => {
                        return ele.Columnid === item.COLUMNID;
                    });
                    if (findIndex < 0) {
                        enumList.push({
                            COLUMNID: ele.Columnid,
                            FIENAME: ele.ENUMNAME,
                            dataItems: [
                                {
                                    CODE: ele.Code,
                                    LABEL: ele.Name,
                                },
                            ],
                        });
                    } else {
                        enumList[findIndex].dataItems.push({
                            CODE: ele.Code,
                            LABEL: ele.Name,
                        });
                    }
                });
                this.setDistributionEnumList({ enumList });
                console.log("枚举列表", this.enumList);
            }
        },
        serchdata(args, COLUMNID) {
            this.$emit("handleChange", "conditionSearch", {
                fieldName: COLUMNID,
                fieldValue: "",
                fieldValueList: args[0],
            }); // 将选择的查询条件传出
        },
        handleSearchList() {
            if (this.searchList && this.searchList.length > 0) {
                for (let i = 0; i < this.searchList.length; i++) {
                    if (this.searchList[i].operatorChar === "date") {
                        this.searchList.splice(i, 1);
                    }
                }
            }
        },
        reset() {
            this.$refs.option &&
                this.$refs.option.forEach((comp) => {
                    comp.checkedList = [];
                    comp.checkAll = false;
                });
        },
    },
};
</script>
<style lang="scss" >
.advance-container {
    width: 100%;
    height: 100%;
    padding: 10px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    > div {
        min-width: 800px;
        width: 100%;
        height: 42px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        &:nth-of-type(n + 1) {
            border-bottom: 1px solid #d4d8dd;
        }
        > label {
            font-family: "微软雅黑";
            font-size: 14px;
            min-width: 150px;
            text-align: right;
            color: rgb(119, 119, 119);
            padding-right: 5px;
            font-weight: 700;
            > span {
                line-height: 42px;
                padding-right: 5px;
            }
        }
        > div {
            label.is-checked {
                text-align: center;
                height: 30px;
                border-radius: 6px;
                > span.el-checkbox__label {
                    line-height: 30px;
                    color: rgb(42, 99, 213);
                }
            }
            > label {
                > span.el-checkbox__label {
                    line-height: 42px;
                    color: rgb(42, 99, 213);
                }
            }
            > div {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                > label {
                    margin: 0 10px !important;
                    max-width: 120px;
                    min-width: 120px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    > span.el-checkbox__label {
                        line-height: 30px;
                        color: rgb(42, 99, 213);
                    }
                }
            }
        }
    }
}
</style>

