<template>
    <div id="basic-table"
         class="mainbox monitoring_table">
        <sn-bread :itemName="itemName"
                  :breadlist="breadlist"
                  v-if="system == 'system1'"></sn-bread>
        <sn-bread :itemName="itemName"
                  :breadlist="breadlist2"
                  v-else></sn-bread>

        <div v-if="picTable"
             class="tableBox">
            <sn-table-group ref="tableGroup"
                            :tableGroupAttributes="tableGroupAttributes"
                            :commonMethods.sync="commonMethods"
                            :class="{ showRightTable: currentItem === '1501' }"
                            @handleChange="handleChange"
                            @getTableData="getTableData">
                <div v-show="conditionSearchShow"
                     class="monitoringdata-condition">
                    <AdvancedSearch ref="conditionSearch"
                                    :tableId.sync="currentItem"
                                    :includes="tableGroupAttributes.includes || []"
                                    :excludes="tableGroupAttributes.excludes || []"
                                    @handleChange="handleChange" />
                </div>
                <!-- 表格内部列表 -->
                <SycnSxjz slot="exp"></SycnSxjz>
                <div v-if="currentItem === '1501'"
                     slot="tableRight"
                     class="tableRight sn_tablebody">
                    <sn-table :snTable.sync="rightTableData.snTable"
                              :commonMethods="commonMethods"
                              :boxHeight.sync="boxHeight"
                              @handleChange="handleChange">
                    </sn-table>
                </div>
            </sn-table-group>
            <sn-dialog :dialogData.sync="dialogData">
                <!-- <div class="table sn_tablebody">
                    <sn-table
                        :snTable.sync="fileData"
                        @handleChange="handleChange"
                    ></sn-table>
                </div> -->
                <SnFileDialog :fileData="fileData"> </SnFileDialog>
            </sn-dialog>
            <!-- 一键上报 回复 -->
            <sn-dialog :dialogData.sync="dialogReply">
                <YjsbljReplyDialog :dialogInfo="dialogReply.row"
                                   @closeReplyDialog="dialogReply.dialog = false"></YjsbljReplyDialog>
            </sn-dialog>
            <!-- 一键上报 上报转 异常 -->
            <sn-dialog :dialogData="dialogTurnError">
                <YjsbljTurnErrorDialog :dialogInfo="dialogTurnError.row"
                                       @closeDialog="closeDialog"></YjsbljTurnErrorDialog>
            </sn-dialog>
            <!-- 一键上报 异常转预警 -->
            <sn-dialog :dialogData="dialogTurnWarn">
                <YjsbljTurnWarnDialog :dialogInfo="dialogTurnWarn.row"
                                      @closeDialog="closeDialog"></YjsbljTurnWarnDialog>
            </sn-dialog>

            <sn-dialog :dialogData.sync="dialogDisease">
                <DiseaseHistoryData v-if="dialogDisease.dialog"
                                    :dialogInfo="dialogDisease.row"
                                    :currentItem="currentItem"
                                    @closeDiseaseDialog="dialogDisease.dialog = false">
                </DiseaseHistoryData>
            </sn-dialog>
        </div>
        <PicCompare v-else
                    :key="currentChild.ItemID"
                    :itemCode="currentChild.ItemID"
                    @changePicTable="
                (flag) => {
                    picTable = flag;
                }
            "></PicCompare>
        <!-- 制茶种茶图片列表 -->
        <div class="zczcBtnPart"
             v-if="currentItem == 183">
            <div class="numBtn zczcBtnItem pointer"
                 v-if="zczcPart.activeNum == 0"
                 @click="changeZczc(1)">
                图片展示
            </div>
        </div>
        <div class="zczcImgPart"
             v-if="zczcPart.dialog">
            <ZczcImg @changeZczc="changeZczc(0)"></ZczcImg>
        </div>
    </div>
</template>
<script>
import infoMixin from "./info_mixin.js";
import customSelect from "./table_type/0_com_js/custom_select.js"; //自定义下拉框

import { mapGetters, mapMutations, mapActions } from "vuex";
//在监测数据里调用基础数据 start
import monitorToBasicDataMap from "./table_config/monitor_to_basic"; //调用的基础数据 在基础数据模块
import basicInMonitor from "./table_config/basic_in_monitor"; //调用的基础数据 在监测数据模块
//在监测数据里调用基础数据 end
import monitoringdata from "@router/modules/monitor_data";
import AdvancedSearch from "@comp/AdvancedSearch.vue";
import SycnSxjz from "./comp/sycn_sxjz";
import PicCompare from "./comp/picCompare";

import DiseaseHistoryData from "./comp/disease_history_data"; //病害历史数据
import { commonData } from "./table_config/table_group/sn-table-group-common";
import { commonMethods } from "./table_config/table-common-methods";
import { rightTableData } from "./table_config/table_group/sn-table-1501";
import shzt_col from "./table_config/table_group/sn-table-column-shzt"; //审核
import djzt_col from "./table_config/table_group/sn-table-column-djzt"; //对接
import connect_zpt_no from "./table_config/connect_zpt_no"; // 不需要对接总平台的项
import sh_btn_no from "./table_config/sh_btn_no"; // 不需要审核按钮的项
import ZczcImg from "./comp/ZczcImg"; // 制茶种茶图片列表

export default {
    mixins: [infoMixin, customSelect,],
    components: {
        AdvancedSearch,
        SycnSxjz,
        PicCompare,
        DiseaseHistoryData,
        ZczcImg,
    },
    name: "application-text",
    beforeRouteLeave (to, from, next) {
        if (to.name === "default") {
            next({ name: "home" });
        } else {
            next();
        }
    },
    props: {
        itemName: {
            type: String,
            default: function () {
                return "";
            },
        },
        currentChild: {
            default: function () {
                return {};
            },
        },
    },
    data () {
        return {
            picTable: true,
            monitoringdata, // 组件挂载时候根据 路由配置，生成 ItemID和 录入页面要跳转路由映射的对象
            conditionSearchShow: false,
            condition: [],
            conditionList: `[]`,
            tableId: "",
            initial_tabletop: null,
            commonData,
            commonMethods,
            tableGroupAttributes: {},

            currentItem: null,
            currentMC: "",
            total: 20, // 每页的条数
            keyword: "",
            status: "",
            multipleSelection: [],
            sort: "",
            order: "",
            start: "",
            end: "",
            dialogData: {
                title: "附件列表",
                dialog: false,
            },
            dialogDisease: {
                title: "监测数据",
                dialog: false,
                row: "",
                class: "disease_history_data",
                width: "1000px",
            },
            breadlist: [
                {
                    path: "",
                    name: "工作台面",
                },
                {
                    path: "",
                    name: "监测数据",
                },
            ],
            breadlist2: [
                {
                    path: "",
                    name: "数据管理",
                },
            ],
            breadlist3: [
                {
                    path: "",
                    name: "数据管理",
                },
            ],
            searchData: [
                { type: "CNSXLYLX" },
                { type: "XZPG" },
                { type: "YCYSBM" },
                { type: "YEAR2" },
                { type: "YCLX" },
                // 古茶树
                { type: "CM" },
                { type: "ZM" },
                // 民居建设
                // 民居建设监测
                { type: "YCCZ" },
                { type: "YCYS" },
            ],
            fileData: {
                mp3List: [],
                mp4List: [],
                imgList: [],
                othersPart: {
                    key: "CCLJ",
                    name: "WDMC",
                    list: [],
                },
                table: {
                    data: [],
                    showHeader: false,
                },
                columns: {
                    // 数据列
                    columnsData: [
                        {
                            prop: "SYTLJ",
                            label: "示意图",
                            width: 60,
                            showImg: true,
                        },
                        {
                            prop: "CCLJ",
                            label: "附件",
                            width: 90,
                            showFileBtn: true,
                            operateType: "dialogFileBtn",
                        },
                        {
                            prop: "WDMC",
                            label: "基础数据名称",
                        },
                    ],
                },
            },
            boxHeight: 0,
            rightTableData,
            // 种茶制茶
            zczcPart: {
                dialog: false,
                activeNum: 0,
            },
        };
    },
    computed: {
        ...mapGetters(["heritageId", "system"]),
        routeMap: function () {
            const res = {};
            const monitoringdata = this.monitoringdata;
            if (
                monitoringdata &&
                monitoringdata.children &&
                Array.isArray(monitoringdata.children)
            ) {
                const { children } = monitoringdata;
                children.forEach((child) => {
                    child.itemId && (res[child.itemId] = child.name);
                });
            }
            return res;
        },
    },
    watch: {
        currentChild: {
            deep: true,
            immediate: false,
            handler () {
                // true 从详情返回页面的时候需要调用服务 此时 currentChild 不会改变
                // 切换子菜单 清空搜索和分页
                localStorage.removeItem("snSearch");
                localStorage.removeItem("snPage");
                if (Object.keys(this.currentChild).length === 0) return false;
                this.changeModule(this.currentChild);
            },
        },
        conditionSearchShow: {
            handler: function (flag) {
                $(
                    $(".monitoringdata-condition~.sn_tablebody")[0]
                )[0].style.top = "80px";
                if (!this.initial_tabletop) {
                    this.initial_tabletop = $(
                        $(".monitoringdata-condition~.sn_tablebody")[0]
                    )[0].offsetTop;
                }
                this.$nextTick(() => {
                    if (flag) {
                        const tabletop = $(
                            $(".monitoringdata-condition~.sn_tablebody")[0]
                        )[0].offsetTop;
                        const slotheight = $(
                            $(".monitoringdata-condition")[0]
                        )[0].offsetHeight;
                        $(
                            $(".monitoringdata-condition~.sn_tablebody")[0]
                        )[0].style.top = tabletop + slotheight + "px";
                    } else {
                        $(
                            $(".monitoringdata-condition~.sn_tablebody")[0]
                        )[0].style.top = this.initial_tabletop + "px";
                    }
                });
            },
        },
    },
    created () { },
    mounted () {
        if (Object.keys(this.currentChild).length === 0) return false;
        this.changeModule(this.currentChild);
    },
    methods: {
        ...mapActions([
            "dockData",
            "getMonitorDataList",
            "getBasicList",
            "deleteBasicData",
            "deleteMonitorData",
            "exportMonitorData",
            "getAllEnumInfobytableID",
            "WdfxToYcjl",
            "recalculateDatacode",
            "GetYear",
            "WdfxToYcjl",
            "ImportNatureAvgData",
        ]),
        // 各种操作
        handleChange (type, value) {
            console.log("type", type, "value", value);
            switch (type) {
                case "load": // 展开子节点(展开再去调服务)
                    this.getRowChildData(value);
                    break;
                case "buttonNew": // 新建
                    this.toNewPage("", "0", false, false);
                    break;
                case "columnEdit": // 编辑
                    this.toNewPage(value[0], "1", false, false);
                    break;
                case "columnEditAdmin": // 编辑 管理员
                    this.toNewPage(value[0], "999", false, false);
                    break;
                case "columnAdd": // 增加子要素
                    this.toNewPage(value[0], "0", false, true);
                    break;
                case "columnRefresh": // 版本变更
                    this.toNewPage(value[0], "1", true, false);
                    break;
                case "columnSee": // 查看
                    this.toNewPage(value[0], "2", false, false);
                    break;
                case "columnCheck": // 审核
                    this.toNewPage(value[0], "3", false, false);
                    break;
                case "columnDelete": // 删除
                    this.deleteData(value[0].ID, this.currentItem);
                    break;
                case "buttonDel": // 批量删除
                    this.Deletesome();
                    break;
                case "buttonLoad": //下载
                    this.loadData();
                    break;
                case "buttonImport": // 导入
                    this.importData(value);
                    break;
                case "buttonExport": // 导出
                    this.exportData();
                    break;
                case "total": // 每页条数
                    this[value[1]] = value[0];
                    this.tableGroupAttributes.snPage.tiaoshu = value[0];
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    // 将页码信息 放入到 localStorage
                    localStorage.setItem(
                        "snPage",
                        JSON.stringify(this.tableGroupAttributes.snPage)
                    );
                    this.getTableData();
                    break;
                case "page": // 当前是第几页
                    this.tableGroupAttributes.snPage.currentPage = value[0];
                    // 将页码信息 放入到 localStorage
                    localStorage.setItem(
                        "snPage",
                        JSON.stringify(this.tableGroupAttributes.snPage)
                    );
                    this.getTableData();
                    break;
                case "search": // 修改搜索条件(搜索及按钮)
                    this[value[1]] = value[0];
                    this.searchData.forEach((item) => {
                        if (item.type === value[1]) {
                            item.value = value[0];
                        }
                    });
                    // 关键字
                    if (value[1] == "keyword") {
                        this.tableGroupAttributes.snSearch.inputData[0].default =
                            value[0];
                    }
                    // select 框
                    this.tableGroupAttributes.snSearch.selectData.map(
                        (item) => {
                            if (item.value === value[1]) {
                                item.default = value[0];
                            }
                        }
                    );
                    // treeselectData
                    if (this.tableGroupAttributes.snSearch.treeselectData) {
                        this.tableGroupAttributes.snSearch.treeselectData.map(
                            (item) => {
                                if (item.value === value[1]) {
                                    item.default = value[0];
                                }
                            }
                        );
                    }

                    // 将搜索信息 放入到 localStorage
                    localStorage.setItem(
                        "snSearch",
                        JSON.stringify(this.tableGroupAttributes.snSearch)
                    );
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    // 将页码信息 放入到 localStorage
                    localStorage.setItem(
                        "snPage",
                        JSON.stringify(this.tableGroupAttributes.snPage)
                    );
                    this.getTableData(); // 是否需要点击查询按钮才进行搜索
                    break;
                case "searchBtn": // 点击查询按钮
                    this.getTableData();
                    break;
                case "selection": // 选择左侧复选框
                    this.selectData(value[0]);
                    break;
                case "fileBtn": // 点击表格的附件
                    this.getFileList(value);
                    break;
                case "dialogFileBtn": // 点击弹出框的附件
                    this.openFile(value);
                    break;
                case "sortable": // 排序
                    this.sortableData(value[0]);
                    break;
                case "conditionSearchBtn": // 切换高级搜索按钮
                    this.switchConditionSearch();
                    break;
                case "conditionSearch": // 高级查询，条件搜索
                    this.changeCondition(value);
                    break;
                case "rowClick": // 点击表格行
                    this.getRightTable(value[0]);
                    break;
                case "toDiseaseMap": // 跳转到病害位置
                    this.toDiseaseMap();
                    break;
                case "toZtgjbhjlMap":
                    this.toZtgjbhjlMap(); // 展示总体格局变化记录
                    break;
                case "showNotPassReason":
                    this.showNotPassReason(value[0].SHBTGSM);
                    break;
                case "columnConnect": // 对接功能
                    this.connectToZptTest(value[0]);
                    break;
                //日常管理 start
                case "replyMsg": // 回复功能
                    this.dialogReply.dialog = true;
                    this.dialogReply.row = value[0];
                    break;
                case "toAbnormal": // 上报转异常
                    this.dialogTurnError.dialog = true;
                    this.dialogTurnError.row = value[0];
                    break;
                case "toWarn": // 异常转预警
                    this.dialogTurnWarn.dialog = true;
                    this.dialogTurnWarn.row = value[0];
                    break;
                // case "toAbnormal": // 转为异常
                //     this.toAbnormalData(value[0]);
                //     break;
                //日常管理 end
                case "toPicCompare":
                    this.toPicCompare();
                    break;
                case "textClick": // 点击表格单元格
                    this.getFileTable(value[0]);
                    break;
                case "columnYqOperate": // 舆情处置
                    this.dealYq(value[0]);
                    break;
                case "showHistoryData": // 病害历史数据
                    this.dialogDisease.dialog = true;
                    this.dialogDisease.row = value[0];
                    this.dialogDisease.title =
                        this.dialogDisease.row && this.dialogDisease.row.SBMC;
                    break;
                case "columnSee402": // 病害历史数据
                    this.$router.push({
                        name: editPage,
                        query: {
                            ID: "",
                            type: type,
                            CNSXID: ID,
                            ItemID: bm,
                            RWID: val.RWID || null,
                        },
                    });
                    break;
                case "jxsk_xmdy": // 建设控制 项目调研
                    this.$router.push({
                        name: "xmdy_map",
                    });
                    break;
                default:
                    this.handleChangeSelf(type, value);
                    break;
            }
        },
        handleChangeSelf () { },
        getFileTable (data) {
            if (data.wdmc === "查看") {
                this.fileData.table.data = data.PIC;
                this.dialogData.dialog = true;
            }
        },
        // 舆情处置
        dealYq (row) {
            this.$router.push({
                name: "yqjl",
                query: {
                    ID: row.ID,
                    type: "1",
                    yqName: row.BT, // 舆情名称
                    yqFbsj: row.FBSJ, // 舆情发布时间
                    yqLy: row.LY, // 舆情来源
                },
            });
        },
        toPicCompare () {
            this.picTable = false;
        },
        // 对接到总平台
        connectToZptTest (value) {
            this.$confirm("对接到总平台, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.connectToZpt(value);
                })
                .catch(() => {
                    this.common.showMsg("已取消", "info");
                });
        },
        async connectToZpt (value) {
            let funId = "";
            // 只做展示的 监测数据要去请求 基础数据列表
            if (monitorToBasicDataMap[this.currentItem]) {
                funId = monitorToBasicDataMap[this.currentItem];
            } else if (basicInMonitor[this.currentItem]) {
                //调用的基础数据 在监测数据模块
                funId = basicInMonitor[this.currentItem];
            } else {
                // 调用监测数据
                funId = this.currentItem;
            }
            // 获取数据用哪一个 funId 最接总平台  就哪一个finId
            const result = await this.dockData({
                // funId: this.$route.params.ItemID,
                funId: funId,
                id: value.ID,
            });
            if (result.IsSuccess) {
                this.getTableData();
                this.common.showMsg("对接成功", "success");
            } else {
                this.common.showMsg("对接失败", "error");
            }
        },
        async toAbnormalData (row) {
            const res = await this.WdfxToYcjl({
                fxId: row.ID,
                userId: localStorage.getItem("userId"),
            });
            if (res.IsSuccess) {
                this.common.showMsg("成功转为异常", "success");
                this.getTableData();
            }
        },
        showNotPassReason (text) {
            this.$alert(text, "审核未通过原因", {
                confirmButtonText: "确定",
            });
        },
        toDiseaseMap () {
            this.$router.push({
                name: "diseaseMap",
            });
        },
        toZtgjbhjlMap () {
            this.$router.push({
                name: "ztgjbhjlMap",
            });
        },
        // 高级搜索
        changeCondition (value) {
            const index = this.condition.findIndex(
                (item) => item.fieldName === value.fieldName
            );
            if (index >= 0) {
                this.condition[index] = { ...value, operatorChar: "=" };
            } else {
                this.condition.push({ ...value, operatorChar: "=" });
            }
            const condition = this.condition.filter(
                (item) => item.fieldValueList.length > 0
            );
            this.searchData = condition.map((ele) => {
                return {
                    type: ele.fieldName,
                    value: ele.fieldValueList.join(),
                    multiple: true,
                };
            });
            this.getTableData();
        },
        // 切换条件搜索按钮  重置条件搜索参数
        switchConditionSearch () {
            this.conditionSearchShow = !this.conditionSearchShow;
            if (!this.conditionSearchShow) {
                this.searchData.forEach((ele) => {
                    delete ele.value;
                });
                this.getTableData();
                this.$refs.conditionSearch.$refs.option.forEach((ele) =>
                    ele.reset()
                );
            }
        },
        mergeSubs (result, subResult) {
            const origin = {};
            result.forEach((item) => {
                origin[item.ID] = { ...item, subs: [] };
            });
            subResult.forEach((subitem) => {
                const itemObj = {
                    CNLY: origin[subitem["CNSXID"]].CNLY,
                };
                origin[subitem["CNSXID"]].subs.push({ ...subitem, ...itemObj });
            });
            // 申遗承诺事项的最终结果
            const mergeResult = Object.values(origin).map((item) => {
                item.subs.sort((a, b) => Number(b.NF) - Number(a.NF));
                return item;
            });
            return mergeResult;
        },
        // 获取表格数据
        async getTableData () {
            // 处理筛选，支持多项同时筛选
            let strwhere = "";
            if (this.searchData && this.searchData.length > 0) {
                this.searchData.forEach((item, index) => {
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        strwhere += '"' + item.type + "," + item.value + '",';
                    }
                });
            }

            // switch (this.currentItem) {
            //     case "1205":
            //         if (this.YCLX) {
            //             strwhere += '"' + "YCLX" + "," + this.YCLX + '",';
            //         }
            //         break;
            // }
            strwhere = strwhere.slice(0, strwhere.length - 1);
            strwhere = "[" + strwhere + "]";
            // const multipleKeyword = (this.searchData || []).filter(item => (item || {}).multiple).map(ele => ele.value).join();
            // const comma = (this.keyword && multipleKeyword) ? ',' : "";
            const keyword = this.keyword;
            this.tableGroupAttributes.snTable.table.data = [];
            this.tableGroupAttributes.snTable.table.loading = true;
            let result;
            // 只做展示的 监测数据要去请求 基础数据列表
            if (monitorToBasicDataMap[this.currentItem]) {
                //调用的基础数据 在基础数据模块
                result = await this.getBasicList({
                    userid: localStorage.userId,
                    ycdid: this.heritageId,
                    itemId: monitorToBasicDataMap[this.currentItem],
                    authority: false,
                    keyWord: this.keyword,
                    strwhere: strwhere,
                    pageIndex: this.tableGroupAttributes.snPage.currentPage,
                    pageSize: this.tableGroupAttributes.snPage.tiaoshu,
                    sort: this.sort,
                    order: this.order,
                    start: this.start,
                    end: this.end,
                });
            } else if (basicInMonitor[this.currentItem]) {
                //调用的基础数据 在监测数据模块
                result = await this.getMonitorDataList({
                    userid: localStorage.userId,
                    itemId: basicInMonitor[this.currentItem],
                    authority: false,
                    keyword,
                    strwhere: strwhere,
                    sort: this.sort,
                    sortorder: this.order,
                    start: this.start,
                    end: this.end,
                    pageIndex: this.tableGroupAttributes.snPage.currentPage,
                    pageSize: this.tableGroupAttributes.snPage.tiaoshu,
                    start: this.start,
                    end: this.end,
                });
            } else {
                // 调用监测数据
                result = await this.getMonitorDataList({
                    userid: localStorage.userId,
                    itemId: this.currentItem,
                    authority: false,
                    keyword,
                    strwhere: strwhere,
                    sort: this.sort,
                    sortorder: this.order,
                    start: this.start,
                    end: this.end,
                    pageIndex: this.tableGroupAttributes.snPage.currentPage,
                    pageSize: this.tableGroupAttributes.snPage.tiaoshu,
                    start: this.start,
                    end: this.end,
                });
            }

            let da = result.ResultValue;
            // this.tableGroupAttributes.snTable.table.data = this.dealData(da.gridData);
            this.tableGroupAttributes.snTable.table.data = da.gridData;
            this.tableGroupAttributes.snPage.count = Number(da.total) || 0;
            this.tableGroupAttributes.loading = false;
            this.getRightTable(this.tableGroupAttributes.snTable.table.data[0]);
            this.getDataNext();
        },
        // 配置数据
        getDataNext () {
            if (this.currentItem == "90201") {
                this.tableGroupAttributes.snTable.table.data.map((item) => {
                    item.KGRQ = item.KGRQ.slice(0, 7);
                    item.JGSJ = item.JGSJ.slice(0, 7);
                });
            }
        },
        // 整理右侧表格结构
        getRightTable (data) {
            if (!data) return false;
            if (this.currentItem === "1501" && data.LBMC === "工程项目") {
                let newData = JSON.parse(JSON.stringify(data));
                let tabledata = newData.PIC;
                let IDs = [];
                this.rightTableData.snTable.table.data = [];
                this.$refs.tableGroup.$refs.snTable.$refs.commonTable.setCurrentRow(
                    data
                ); // 选中当前行
                this.currentRow = data;
                tabledata.forEach((item) => {
                    item.ID = this.setID();
                    IDs.push(item.ID);
                    item.Children = item.PIC || [];
                    item.SHZT = item.shzt;
                    delete item.PIC;
                    item.Children &&
                        item.Children.forEach((ele) => {
                            ele.ID = this.setID();
                            ele.SHZT = ele.shzt;
                            if (ele.name === "其他") {
                                ele.wdmc =
                                    ele.sfyqtfile === "1"
                                        ? ele.PIC.length === 0
                                            ? "待上传"
                                            : "查看"
                                        : "无";
                                ele.shzt = ele.wdmc === "无" ? "" : ele.shzt;
                            } else {
                                ele.wdmc =
                                    ele.PIC.length === 0 ? "待上传" : "查看";
                            }
                        });
                });
                this.rightTableData.snTable.table.data = tabledata;
                this.rightTableData.snTable.table.expandRowKeys = IDs;
            }
        },
        setID () {
            return (
                (Math.random() * 10000000).toString(16).substr(0, 4) +
                "-" +
                new Date().getTime() +
                "-" +
                Math.random().toString().substr(2, 5)
            );
        },
        // 部分项数据展示需特殊处理
        dealData (gridData) {
            switch (this.currentItem) {
                case "101":
                    gridData.forEach((row) => {
                        row.ID = row.CNSXID;
                    });
                    return gridData;
                default:
                    return gridData;
            }
        },
        // 展开子节点(展开再去调服务)
        getRowChildData (value) { },
        // 跳转到新建或编辑页面
        toNewPage (val, type, updataVersion, addChild) {
            // type: 0 => 新建 1 => 编辑 2 => 查看
            let bm = this.$route.params.ItemID;

            let ID = val.ID;
            let version = val.BBH;
            let fid = val.ID;
            let editPage = this.routeMap[bm];
            if (Number(this.currentItem) === 101) {
                this.$router.push({
                    name: editPage,
                    query: {
                        ID: "",
                        type: type,
                        CNSXID: ID,
                        ItemID: bm,
                        RWID: val.RWID || null,
                    },
                });
                return;
            }
            if (editPage) {
                this.$router.push({
                    name: editPage,
                    query: {
                        val: JSON.stringify(val),
                        type: type,
                        id: addChild ? "" : ID,
                        // LB: this.$route.params.LB,
                        version: updataVersion ? version : "",
                        fid: addChild ? fid : "",
                        ItemID: bm,
                        basicType: this.$route.params.basicType,
                        MC: this.$route.params.MC,
                    },
                });
            }
        },
        // 删除前确认
        deleteData (value, itemID) {
            let checkboxlist = value;
            this.$confirm("与之相关的数据会受到影响,是否删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.delData(checkboxlist, itemID);
                })
                .catch(() => {
                    this.common.showMsg("已取消删除", "info");
                });
        },
        // 删除数据
        async delData (id, itemID) {
            let res = await this.deleteMonitorData({
                ids: id,
                itemid: this.currentItem,
            });
            if (res.IsSuccess) {
                this.common.showMsg("删除成功", "success");
                this.getTableData();
            } else {
                this.common.showMsg("删除失败", "error");
            }
        },
        // 批量删除
        Deletesome () {
            let checkboxlist = [];
            this.multipleSelection.forEach(function (o) {
                checkboxlist.push(o);
            });
            if (checkboxlist.length === 0) {
                this.common.showMsg("请选择数据项！", "warning");
            } else {
                checkboxlist = checkboxlist.join();
                this.deleteData(checkboxlist);
            }
        },
        // 点击选择框
        selectData (value) {
            this.multipleSelection = [];
            if (value && value.length > 0) {
                value.forEach((item) => {
                    this.multipleSelection.push(item.ID);
                });
            }
        },
        // 表格内容升降序排列
        sortableData (value) {
            this.order = value.order
                ? value.order.slice(0, value.order.length - 6)
                : null;
            this.sort = value.order ? value.prop : null;
            this.getTableData();
        },
        // 点击附件图标的操作
        getFileList (value) {
            let list = value[0].PIC;
            let num = list.length;
            if (num === 1) {
                // 直接点开或者下载
                this.openFile(list);
            } else {
                this.fileData.mp3List = [];
                this.fileData.mp4List = [];
                this.fileData.imgList = [];
                this.fileData.othersPart.list = [];
                list.map((item) => {
                    let dw = item.CCLJ.substring(
                        item.CCLJ.lastIndexOf(".") + 1
                    );
                    switch (dw) {
                        case "mp3":
                            this.fileData.mp3List.push(item.CCLJ);
                            break;
                        case "mp4":
                            this.fileData.mp4List.push(item.CCLJ);
                            break;
                        case "jpg":
                        case "png":
                        case "jpeg":
                        case "gif":
                        case "tiff":
                            this.fileData.imgList.push(item.CCLJ);
                            break;
                        default:
                            this.fileData.othersPart.list.push(item);
                            break;
                    }
                });

                this.fileData.table.data = list;
                this.dialogData.dialog = true;
            }
        },
        // 下载附件
        openFile (value) {
            window.open(value[0].CCLJ);
        },
        // 导出
        exportData () {
            let para = {
                itemId: "",
            };
            let url = "";
            if (monitorToBasicDataMap[this.currentItem]) {
                para.itemId = monitorToBasicDataMap[this.currentItem];
                url = `GatherPart/ExportBasicData`;
            } else {
                switch (this.currentItem) {
                    case "90502":
                        // 多选
                        para.Ids = this.multipleSelection.join(",");
                        url = "MonitorData/ExportMJJSMonitorData";
                        break;
                    default:
                        url = "MonitorData/ExportMonitorData";
                        break;
                }
                para.itemId = this.currentItem;
            }

            let obj = {
                url: window.REQUEST_URL + url,
                name: this.currentMC + ".xls",
                para: para,
            };
            this.common.exportFile(obj);
        },
        // 导入
        async importData (file) {
            const form = new FormData();
            form.append("code", this.currentItem);
            form.append("file", file.raw);
            let result = await $AXIOS({
                method: "post",
                url: `${window.REQUEST_URL}MonitorData/ImportNatureAvgData`,
                dataType: "json",
                data: form,
            });
            if (result.data.ISSUCCESS) {
                this.common.showMsg("导入成功", "success");
                this.getTableData();
            } else {
                this.common.showMsg("导入失败", "error");
            }
        },
        // 下载
        loadData () {
            let url = "";
            switch (this.currentItem) {
                case "8020102": //温度月度记录
                    url = `${window.DOWN_URL}/Import-Nature/DownLoad/澜沧县景迈国家地面天气站月平均气温统计表_模板.xlsx`;
                    break;
                case "8020104": //降雨量月度记录
                    url = `${window.DOWN_URL}/Import-Nature/DownLoad/澜沧县景迈国家地面天气站月降雨量统计表_模板.xlsx`;
                    break;
            }
            window.open(url);
        },
        // 改变左侧项
        async changeModule (params) {
            if (!params) return;
            let name = "";
            switch (params.ItemID) {
                case "301": //遗产总图
                    name = "monitor_yczt";
                    break;
                case "302": //遗产要素分布图
                    name = "monitor_ycysfbt";
                    break;
                case "303": //使用功能基准图
                    name = "monitor_sygnjzt";
                    break;
                case "304": //遗产要素单体或局部测绘基准图和标志性影像
                    name = "ycysdthjbchjzthbzyx";
                    break;
                case "90101": //保护区划图及保护区划界线描述与管理规定 遗产区缓冲区
                    name = "ycqhcq";
                    break;
                case "90304": //保护区划图及保护区划界线描述与管理规定 遗产区缓冲区
                    name = "jgfm_time_list";
                    break;
            }

            // 特殊路由跳转
            if (name) {
                this.$router.push({ name: name });
            } else {
                // 正常表单跳转
                this.$router.push({
                    name: "monitoringTable",
                    params: params,
                });
                Object.assign(this.$data, this.$options.data());

                this.currentItem = params.ItemID;
                this.currentMC = params.MC;
                if (!this.currentItem) {
                    return;
                }
                const moduleData = await import(
                    `./table_config/table_group/sn-table-group-${this.currentItem}`
                );
                let copyObj = JSON.parse(JSON.stringify(moduleData.default));
                let comObj = JSON.parse(JSON.stringify(this.commonData));
                this.handleDataAll(copyObj, comObj);
                const columns = copyObj.snTable.columns;
                const columnsData = columns.columnsData;
                const columnsBtn = columns.columnsBtn;
                columnsBtn.width = columns.width || 120;
                const djBtn = {
                    isShow: true,
                    showMethod: "showConnect",
                    btnType: "icon",
                    operateType: "columnConnect",
                    title: "对接",
                    icon: "el-icon-upload",
                    backColor: "transparent",
                    color: "#008c8c",
                    width: "120px",
                };
                const shBtn = {
                    isShow: true,
                    showMethod: "showCheckBtn",
                    btnType: "icon",
                    operateType: "columnCheck",
                    title: "审核",
                    icon: "el-icon-document-checked",
                    backColor: "transparent",
                    color: "#00a7bd",
                    width: "120px",
                };

                // 审核列 数据状态 是否存在 在配置文件里面配置
                // if (columnsData.find((ele) => ele.prop === shzt_col.prop)) {
                //     columnsData.push(shzt_col);
                // }
                // 对接列 对接状态 是否存在
                // if (columnsData.find((ele) => ele.prop === djzt_col.prop)) {
                //     columnsData.push(djzt_col);
                // }
                // 审核按钮 是否存在
                if (!sh_btn_no.includes(String(this.currentItem))) {
                    columnsBtn.buttonData.push(shBtn);
                }
                // 对接按钮 是否存在
                if (!connect_zpt_no.includes(String(this.currentItem))) {
                    columnsBtn.buttonData.push(djBtn);
                }
                this.tableGroupAttributes = copyObj;
                await this.addSelectByEnum(); //从枚举获取数据 添加筛选项
                // 给 tableGroupAttribute 配 自定义 transList
                switch (this.currentItem) {
                    case "603": //遗产要素现状记录
                        await this.getYcysData();
                        break;
                    case "307": //古茶树
                    case "90501": //民居建设;
                    case "90502": //民居建设监测;
                        await this.getCountryGroup(this.currentItem); //村 组
                        break;
                    // case "8020103": //微环境气象监测;
                    // case "8020204": //微环境空气质量监测;
                    // case "8020504": //微环境土壤监测;
                    // case "8020510": //微环境水文水质监测;
                    // case "8020102": //温度月度记录;
                    // case "8020104": //降雨量月度记录;

                }
                if (this.currentItem === "1501") {
                    this.boxHeight = $(".tableRight").height();
                }
                // 将页码信息 放入到 localStorage
                if (localStorage.snPage) {
                    // 保存完 数据 返回 赋值
                    let snPage = JSON.parse(localStorage.snPage);
                    this.tableGroupAttributes.snPage.currentPage =
                        snPage.currentPage;
                    this.tableGroupAttributes.snPage.tiaoshu = snPage.tiaoshu;
                    this.tableGroupAttributes.snPage.count = snPage.count;
                } else {
                    this.tableGroupAttributes.snPage.tiaoshu = 20;
                    this.tableGroupAttributes.snPage.currentPage = 1;
                }
                //搜索部分参数 返回页面 重新赋值
                if (localStorage.snSearch) {
                    let snSearch = JSON.parse(localStorage.snSearch);
                    // 关键字
                    if (snSearch.inputData && snSearch.inputData[0].default) {
                        // 样式
                        this.tableGroupAttributes.snSearch.inputData[0].default =
                            snSearch.inputData[0].default;
                        // 请求参数
                        this.keyword = snSearch.inputData[0].default;
                    }
                    // select 下拉框
                    snSearch.selectData.map((item) => {
                        if (item.default) {
                            // 样式
                            this.tableGroupAttributes.snSearch.selectData.map(
                                (item1) => {
                                    if (item1.value === item.value) {
                                        item1.default = item.default;
                                    }
                                }
                            );
                            // 请求参数
                            this.searchData.map((item1) => {
                                if (item1.type === item.value) {
                                    item1.value = item.default;
                                }
                            });
                        }
                    });
                    // treeselectData 下拉框
                    snSearch.treeselectData.map((item) => {
                        if (item.default) {
                            // 样式
                            this.tableGroupAttributes.snSearch.treeselectData.map(
                                (item1) => {
                                    if (item1.value === item.value) {
                                        item1.default = item.default;
                                    }
                                }
                            );
                            // 请求参数
                            this.searchData.map((item1) => {
                                if (item1.type === item.value) {
                                    item1.value = item.default;
                                }
                            });
                        }
                    });
                }
                this.getTableData();
            }
        },
        //从枚举获取数据 添加筛选项
        async addSelectByEnum () {
            const exceptItem = [101, 602, 801, 90501, 90502];
            if (exceptItem.includes(Number(this.currentItem))) {
                return;
            }
            let enums = await this.getEnums();
            // 茶特中心 种茶制茶 下拉列表 只有进程  没有部门，大系统有
            if (this.currentItem == "183" && this.system == "system1") {
                let obj = {
                    COLUMNID: "DEPARTMENTID",
                    FIENAME: "部门",
                    TABLEID: "",
                    dataItems: [
                        {
                            CODE: "08edd8ce-07e3-4aac-8bb0-e50055550577",
                            LABEL: "茶厂",
                        },
                        {
                            CODE: "1fc19f4c-d147-4e24-9558-c1853e1e8334",
                            LABEL: "村民",
                        },
                        {
                            CODE: "345c687e-ae31-491f-a038-cef9b1896a06",
                            LABEL: "茶特中心",
                        },
                    ],
                };
                enums.push(obj);
            }

            const selectData = enums.map((ele) => {
                const list = ele.dataItems.map(({ CODE, LABEL }) => ({
                    ID: CODE,
                    NAME: LABEL,
                }));

                let temp = {
                    label: ele.FIENAME,
                    clearable: true,
                    // 请选择
                    placeholder: `${ele.FIENAME}`,
                    list,
                    optionLabel: "NAME",
                    optionValue: "ID",
                    value: ele.COLUMNID,
                    operateType: "search",
                    default: "",
                    isShow: true,
                    multiple: true,
                    width: `${84 + 12 * ele.FIENAME.length < 220
                        ? 220
                        : 84 + 12 * ele.FIENAME.length
                        }px`,
                };
                return temp;
            });

            await this.addSelectYears(selectData); //遥感专项监测 获取时间 年
            const oldSelectData =
                (this.tableGroupAttributes.snSearch || {}).selectData || [];

            selectData.forEach((ele) => {
                const contain = oldSelectData.find(
                    (findItem) => findItem.value === ele.value
                );
                if (!contain) {
                    oldSelectData.push(ele);
                }
            });
            if (!this.tableGroupAttributes.snSearch) {
                this.tableGroupAttributes.snSearch = { selectData: [] };
            }
            this.tableGroupAttributes.snSearch.selectData = [...oldSelectData];
            switch (this.currentItem) {
                case "1205":
                    this.dealSelectYjsb();
            }
            // 处理searchData
            const oldSearch = this.searchData;
            const newSearch = enums.map(({ COLUMNID }) => ({
                type: COLUMNID,
                multiple: true,
            }));
            newSearch.forEach((ele) => {
                const contain = oldSearch.find(
                    (findItem) => findItem.type === ele.type
                );
                if (!contain) {
                    oldSearch.push(ele);
                }
            });
            this.searchData = [...oldSearch];
        },
        // 一键上报下拉框 特殊处理
        async dealSelectYjsb () {
            let list = await this.getSblxAppList();
            this.tableGroupAttributes.snSearch.treeselectData[0].list = list;
        },
        // 遥感专项监测 获取时间 年
        async addSelectYears (selectData) {
            if (Number(this.currentItem) === 90305) {
                let res = await this.GetYear();
                const list = res.Year2.map(({ YEAR2 }) => ({
                    ID: YEAR2,
                    NAME: YEAR2,
                }));
                const selectItem = {
                    label: "年份",
                    clearable: true,
                    placeholder: `请选择年份`,
                    list,
                    optionLabel: "NAME",
                    optionValue: "ID",
                    value: "YEAR2",
                    operateType: "search",
                    isShow: true,
                    multiple: true,
                    width: `180px`,
                };
                selectData.push(selectItem);
            }
        },
        async getEnums () {
            let result = [];
            let num = this.currentItem;
            if (this.currentItem == "202") {
                num = "22";
            }
            const res = await this.getAllEnumInfobytableID({
                bid: num,
            });
            if (res && Array.isArray(res) && res.length > 0) {
                switch (Number(this.currentItem)) {
                    case 90305:
                        res.forEach((item) => {
                            if (item.COLUMNID === "SHZT") {
                                result.push(item);
                            }
                        });
                        break;
                    default:
                        result = res;
                        break;
                }
            }
            return result;
        },

        // 公共的属性可以不写，提取到common中再赋值到各自的组件中
        handleDataAll (self, common) {
            Object.keys(common).forEach((key) => {
                if (self.hasOwnProperty(key)) {
                    if (typeof common[key] !== "object") return false;
                    if (
                        key === "selectData" &&
                        Array.isArray(common[key]) &&
                        common[key].length > 0
                    ) {
                        let hash = {};
                        self[key] = [...self[key], ...common[key]];
                        let newArr = self[key].reduce((item, next) => {
                            // eslint-disable-next-line
                            hash[next.label]
                                ? ""
                                : (hash[next.label] = true && item.push(next));
                            return item;
                        }, []);
                        self[key] = newArr;
                        this.searchData = [];
                        self[key].forEach((item) => {
                            let type = item.value;
                            this.searchData.push({ type: type, value: "" });
                        });
                    }
                    this.handleDataAll(self[key], common[key]);
                } else {
                    self[key] = common[key];
                }
            });
        },
        // 制茶种茶列表切换
        changeZczc (index) {
            this.zczcPart.dialog = !this.zczcPart.dialog;
            this.zczcPart.activeNum = index;
        },
    },
};
</script>
<style lang="scss">
.el-breadcrumb {
    > span:nth-of-type(n + 2) {
        > span {
            font-weight: 400;
            color: #606266;
            cursor: text;
            &:hover {
                font-weight: 400;
                color: #606266;
                cursor: text;
            }
        }
    }
}
.el-icon-arrow-right {
    color: #2a63d5;
    font-weight: 700;
    font-size: 14px;
}
</style>
<style lang="scss" scoped>
.mainbox {
    // TODO
    width: 100%;
    height: 100%;
    position: relative;

    .tableBox {
        width: 100%;
        height: calc(100% - 42px);
        position: absolute;
        top: 42px;
        left: 0;
    }
    // 种茶制茶
    .zczcBtnPart {
        position: absolute;
        top: 52px;
        right: 10px;
        z-index: 10;

        .zczcBtnItem {
            width: 92px;
            height: 30px;
            color: rgba(255, 255, 255, 1);
            line-height: 30px;
            text-align: center;
            font-size: 16px;
            border-radius: 10px;
        }
        .numBtn {
            background: rgba(0, 142, 212, 1);
        }
        .tableBtn {
            background: rgba(238, 123, 81, 1);
        }
    }
    .zczcImgPart {
        width: calc(100% - 20px);
        height: calc(100% - 42px);
        position: absolute;
        top: 42px;
        left: 10px;
        z-index: 10;
        background: #fff;
    }
}
.table.sn_tablebody {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}
</style>
