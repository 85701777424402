<template>
    <div class="zczcImgCtn">
        <div class="zczcBtnPart">
            <div class="tableBtn zczcBtnItem pointer" @click="changeZczc()">
                列表展示
            </div>
        </div>
        <div class="searchPart">
            <sn-search
                class="sn-search clearfix"
                :snSearch.sync="snSearch"
                @handleChange="handleChange"
                style="padding-left: 0; width: auto"
            ></sn-search>
        </div>
        <div class="imgWrap scrollbar">
            <div v-for="(item, index) in listAry" :key="index">
                <div
                    class="titlePart flex-between-y-center pointer"
                    @click="changeItemState(item)"
                >
                    <div class="title">{{ item.title }}</div>
                    <i class="el-icon-arrow-down arrow" v-if="item.state"></i>
                    <i class="el-icon-arrow-right arrow" v-else></i>
                </div>
                <div
                    class="imgPart flex flex-wrap animated fadeIn"
                    v-show="item.state"
                >
                    <div
                        v-for="(itemInner, indexInner) in item.list"
                        :key="indexInner"
                        class="flex"
                    >
                        <div
                            v-for="(itemPic, indexPic) in itemInner.PIC"
                            :key="indexPic"
                            class="imgItem"
                        >
                            <div class="img">
                                <el-image
                                    @click="previewFile(itemPic)"
                                    :src="common.getThumbnailImg(itemPic.CCLJ)"
                                    fit="cover"
                                    class="pointer"
                                    style="width: 100%; height: 100%"
                                >
                                    <div
                                        slot="error"
                                        style="width: 100%; height: 100%"
                                    >
                                        <SnNoData
                                            width="80px"
                                            typeNum="1"
                                        ></SnNoData>
                                    </div>
                                </el-image>
                            </div>
                            <div class="descPart flex-between">
                                <div class="time">{{ itemInner.PZSJ }}</div>
                                <div class="desc">{{ itemInner.DEPNAME }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ViewerAlert :imgList="imgList" ref="myImgList"></ViewerAlert>
    </div>
</template>
<script>
import ViewerAlert from "@comp/ViewerAlert.vue"; //大图查看
import { mapActions, mapGetters } from "vuex";
export default {
    components: {
        ViewerAlert,
    },
    name: "",
    data() {
        return {
            keyword: "",
            snSearch: {
                inputData: [
                    {
                        label: "关键字",
                        placeholder: "请输入关键字",
                        value: "keyword",
                        operateType: "search",
                        isShow: true,
                    },
                ],
                selectData: [],
                searchBtn: {
                    buttonData: [
                        {
                            btnType: "button",
                            operateType: "searchBtn",
                            name: "查询",
                            round: true,
                            backColor: "#2a63d5",
                            color: "#fff",
                        },
                    ],
                },
            },
            imgList: [],
            listAry: [
                {
                    title: "种茶",
                    state: true,
                    list: [],
                },
                {
                    title: "采茶",
                    state: true,
                    list: [],
                },
                {
                    title: "制茶",
                    state: true,
                    list: [],
                },
            ],
            searchData: [{ type: "JC" }, { type: "DEPARTMENTID" }],
        };
    },
    computed: {
        ...mapGetters(["system"]),
    },
    props: {},
    watch: {},
    mounted() {
        this.addSelectByEnum();
        this.getTableData();
    },
    methods: {
        ...mapActions(["getMonitorDataList", "getAllEnumInfobytableID"]),
        //从枚举获取数据 添加筛选项
        async addSelectByEnum() {
            let enums = await this.getEnums();
            // 茶特中心 种茶制茶 下拉列表 只有进程  没有部门，大系统有
            if (this.system == "system1") {
                let obj = {
                    COLUMNID: "DEPARTMENTID",
                    FIENAME: "部门",
                    TABLEID: "",
                    dataItems: [
                        {
                            CODE: "08edd8ce-07e3-4aac-8bb0-e50055550577",
                            LABEL: "茶厂",
                        },
                        {
                            CODE: "1fc19f4c-d147-4e24-9558-c1853e1e8334",
                            LABEL: "村民",
                        },
                        {
                            CODE: "345c687e-ae31-491f-a038-cef9b1896a06",
                            LABEL: "茶特中心",
                        },
                    ],
                };
                enums.push(obj);
            }
            const selectData = enums.map((ele) => {
                const list = ele.dataItems.map(({ CODE, LABEL }) => ({
                    ID: CODE,
                    NAME: LABEL,
                }));

                let temp = {
                    label: ele.FIENAME,
                    clearable: true,
                    // 请选择
                    placeholder: `${ele.FIENAME}`,
                    list,
                    optionLabel: "NAME",
                    optionValue: "ID",
                    value: ele.COLUMNID,
                    operateType: "search",
                    isShow: true,
                    multiple: true,
                    width: `${
                        84 + 12 * ele.FIENAME.length < 220
                            ? 220
                            : 84 + 12 * ele.FIENAME.length
                    }px`,
                };
                return temp;
            });
            this.snSearch.selectData = selectData;
            console.log(
                "%c 🍣 this.snSearch.selectData: ",
                "font-size:20px;background-color: #E41A6A;color:#fff;",
                this.snSearch.selectData
            );
        },
        async getEnums() {
            let result = [];
            const res = await this.getAllEnumInfobytableID({
                bid: 183,
            });
            if (res && Array.isArray(res) && res.length > 0) {
                result = res;
            }
            return result;
        },
        async getTableData() {
            let strwhere = "";
            if (this.searchData && this.searchData.length > 0) {
                this.searchData.forEach((item, index) => {
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        strwhere += '"' + item.type + "," + item.value + '",';
                    }
                });
            }
            strwhere = strwhere.slice(0, strwhere.length - 1);
            strwhere = "[" + strwhere + "]";
            const keyword = this.keyword;
            let result = await this.getMonitorDataList({
                userid: localStorage.userId,
                itemId: 183,
                keyword,
                strwhere: strwhere,
                authority: false,
                pageIndex: 1,
                pageSize: 99999,
            });
            this.listAry[0].list = [];
            this.listAry[1].list = [];
            this.listAry[2].list = [];
            result.ResultValue.gridData.map((item) => {
                switch (item.JC.substring(0, 1)) {
                    case "1": //种茶
                        this.listAry[0].list.push(item);
                        break;
                    case "2": //采茶
                        this.listAry[1].list.push(item);
                        break;
                    case "3": //制茶
                        this.listAry[2].list.push(item);
                        break;
                }
            });
        },
        changeItemState(item) {
            item.state = !item.state;
        },
        //查看上传附件
        previewFile(item) {
            let ary = [];
            let obj = {
                alt: "",
                src: item.CCLJ,
                thumbnail: this.common.getThumbnailImg(item.CCLJ),
            };
            ary.push(obj);
            this.imgList = Array.from(ary);
            if (this.imgList.length) {
                this.$refs.myImgList.showViewer();
            }
        },
        changeZczc() {
            this.$emit("changeZczc");
        },
        // 各种操作
        handleChange(type, value) {
            console.log("type", type, "value", value);

            switch (type) {
                case "search": // 修改搜索条件(搜索及按钮)
                    this[value[1]] = value[0];
                    this.searchData.forEach((item) => {
                        if (item.type === value[1]) {
                            item.value = value[0];
                        }
                    });
                    this.getTableData(); // 是否需要点击查询按钮才进行搜索
                    break;
                case "searchBtn": // 点击查询按钮
                    this.getTableData();
                    break;
            }
        },
    },
};
</script>
<style scoped lang="scss">
.zczcImgCtn {
    width: 100%;
    height: 100%;
    // 种茶制茶
    .zczcBtnPart {
        position: absolute;
        top: 10px;
        right: 0;
        z-index: 10;

        .zczcBtnItem {
            width: 92px;
            height: 30px;
            color: rgba(255, 255, 255, 1);
            line-height: 30px;
            text-align: center;
            font-size: 16px;
            border-radius: 10px;
        }
        .tableBtn {
            background: rgba(238, 123, 81, 1);
        }
    }
    .searchPart {
        height: 50px;
        position: relative;
        .sn-button {
        }
    }
    .imgWrap {
        margin-top: 10px;
        .titlePart {
            width: 120px;
            height: 30px;
            border-radius: 5px;
            background: rgba(46, 90, 67, 1);
            margin-bottom: 10px;
            padding: 0 10px;
            .title {
                color: #fff;
                font-size: 18px;
            }
            .arrow {
                color: #fff;
                font-size: 18px;
            }
        }

        .imgPart {
            .imgItem {
                width: 182px;
                height: 151px;
                margin-right: 23px;
                .img {
                    width: 182px;
                    height: 105px;
                }
                .descPart {
                    padding: 0 10px;
                    background: rgba(222, 236, 229, 1);
                    .time {
                        color: #333;
                    }
                    .desc {
                        color: #333;
                    }
                }
            }
        }
    }
}
</style>
