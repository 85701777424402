// 在监测数据里 没有审核按钮的项目
let list = [
    {
        name: "申遗承诺履行情况",
        itemID: "101"
    },
    {
        name: "保护管理机构",
        itemID: "20101"
    },
    {
        name: "监测机构",
        itemID: "20102"
    },
    {
        name: "专项保护管理法规、规章",
        itemID: "202"
    },
    {
        name: "病害分布图及病害调查监测工作情况记录",
        itemID: "702"
    },
    // 自然环境 start
    {
        name: "气象",
        itemID: "8020101"
    },
    {
        name: "大气质量",
        itemID: "8020202"
    },
    {
        name: "气象灾害",
        itemID: "8020512"
    },
    {
        name: "土壤墒情",
        itemID: "8020504"
    },
    {
        name: "微环境",
        itemID: "8020502"
    },
    {
        name: "水质",
        itemID: "8020510"
    },
    {
        name: "风速",
        itemID: "8020511"
    },

    {
        name: "微环境气象监测",
        itemID: "8020103"
    },
    {
        name: "微环境空气质量监测",
        itemID: "8020204"
    },
    {
        name: "植物多样性监测",
        itemID: "8020514"
    },
    {
        name: "动物多样性监测",
        itemID: "8020515"
    },


    // 自然环境 end
    {
        name: "保护区划界线描述与管理规定",
        itemID: "90102"
    },
    {
        name: "新建项目现场环境照片",
        itemID: "90203"
    },

    {
        name: "日常巡查异常记录",
        itemID: "1201"
    },
    {
        name: "现有安消防系统硬件设施信息",
        itemID: "1301"
    },
    {
        name: "现场跟踪",
        itemID: "1403"
    },
    {
        name: "保护展示与环境整治工程记录",
        itemID: "1501"
    },
    {
        name: "现场照片",
        itemID: "1503"
    },
    {
        name: "保护管理规划编制记录",
        itemID: "1601"
    },
    {
        name: "学术研究",
        itemID: "171"
    },
    {
        name: "种茶制茶",
        itemID: "183"
    },
]

let listNew = []
list.map((item) => {
    listNew.push(item.itemID)
})
export default listNew