export default {
    prop: 'SFYDJ',
    label: '对接状态',
    transList: {
        null: '未对接',
        '': '未对接',
        0: '未对接',
        1: '已对接'
    },
    colorList: {
        null: '#28ccd9',
        '': '#28ccd9',
        0: '#28ccd9',
        1: '#2ec392'
    },
    width: 100,
    tip: true
};