// 在监测数据里 不用对接到总平台
let list = [
    {
        name: "承诺事项进展",
        itemID: "101"
    },
    {
        name: "古树名木",
        itemID: "306"
    },
    {
        name: "古茶树",
        itemID: "307"
    },
    {
        name: "总体格局变化记录及记录图",
        itemID: "402"
    },
    {
        name: "遗产使用功能变化记录及记录图",
        itemID: "502"
    },
    {
        name: "遗产要素现状记录",
        itemID: "603"
    },
    {
        name: "病害监测数据",
        list: [{
            name: "裂缝",
            itemID: "7050201"
        },
        {
            name: "构建变形",
            itemID: "7050202"
        },
        {
            name: "构建缺失",
            itemID: '7050203'
        },
        {
            name: "构建松动",
            itemID: '7050204'
        },
        {
            name: "生物病害",
            itemID: '7050207'
        },
        {
            name: "不均匀沉降",
            itemID: '7050208'
        },
        {
            name: "糟朽",
            itemID: '7050205'
        },
        {
            name: "植物病害",
            itemID: '7050206'
        },
        {
            name: "病虫害-虫情测报",
            itemID: '706'
        },
        {
            name: "森林病虫害遥感监测",
            itemID: '707'
        },
        {
            name: "古茶林病虫害调查",
            itemID: '708'
        },]
    },
    {
        name: "自然环境",
        list: [{
            name: "微环境气象监测",
            itemID: '8020103'
        },
        {
            name: "微环境空气质量监测",
            itemID: '8020204'
        },
        {
            name: "植物多样性监测",
            itemID: '8020514'
        },
        {
            name: "动物多样性监测",
            itemID: '8020515'
        },
        {
            name: "物候监测",
            itemID: '8020516'
        },
        {
            name: "微环境土壤监测",
            itemID: '8020504'
        },
        {
            name: "微环境水文水质监测",
            itemID: '8020510'
        },
        {
            name: "温度月度记录",
            itemID: '8020102'
        },
        {
            name: "降雨量月度记录",
            itemID: '8020104'
        },]
    },
    {
        name: "建设控制",
        list: [{
            name: "项目调研",
            itemID: '90401'
        },
        {
            name: "景观视线基础数据",
            itemID: '90301'
        },
        {
            name: "景观视线监测数据",
            itemID: '90302'
        },
        {
            name: "景观风貌基础数据",
            itemID: '90303'
        },
        {
            name: "景观风貌监测数据",
            itemID: '90304'
        },
        {
            name: "遥感专项监测",
            itemID: '90305'
        },]
    },
    {
        name: "社会环境",
        list: [{
            name: "土地利用现状图",
            itemID: "1001"
        },
        {
            name: "土地利用规划图",
            itemID: "1002"
        }, {
            name: "茶产业",
            itemID: "1008"
        },]
    },
    {
        name: "日常管理",
        list: [{
            name: "日常巡查记录",
            itemID: "1202"
        },
        {
            name: "一键上报记录",
            itemID: "1205"
        },]
    },
    {
        name: "宣传教育",
        list: [{
            name: "宣传教育情况记录",
            itemID: "181"
        },
        {
            name: "宣传教育活动照片",
            itemID: "182"
        },]
    },
    {
        name: "舆情记录",
        itemID: "191"
    },
    {
        name: "非物质文化遗产传承",
        list: [{
            name: "茶叶加工技艺基础数据",
            itemID: "500101"
        },
        {
            name: "茶叶加工技艺监测数据",
            itemID: "500102"
        }, {
            name: "特殊的茶叶利用传统",
            itemID: "500103"
        }, {
            name: "民族文化基础数据",
            itemID: "500201"
        }, {
            name: "民族文化监测数据",
            itemID: "500202"
        },]
    },
    {
        name: "建设控制",
        list: [{
            name: "民居建设",
            itemID: "90501"
        },
        {
            name: "民居建设监控",
            itemID: "90502"
        },]
    },

]

let listNew = []
list.map((item) => {
    if (item.list) {
        item.list.map((item1) => {
            listNew.push(item1.itemID)
        })
    } else {
        listNew.push(item.itemID)
    }
})
export default listNew