const SHZT = window.SHZT;
export default {
    prop: 'SHZT',
    label: '数据状态',
    transList: {
        [SHZT.SUBMITED]: '未审核', // 未审核 / 提交未审核
        [SHZT.NOT_SUBMITED]: '未提交', // 未提交
        [SHZT.AUDIT_PASSED]: '审核通过', // 审核通过
        [SHZT.AUDIT_NOT_PASSED]: '审核不通过', // 审核不通过
    },
    colorList: {
        [SHZT.SUBMITED]: '#28ccd9',
        [SHZT.NOT_SUBMITED]: '#ff9066',
        [SHZT.AUDIT_PASSED]: '#2ec392',
        [SHZT.AUDIT_NOT_PASSED]: '#fa6f1b'
    },
    minWidth: 100,
    tip: true
};