import { mapActions, mapGetters } from 'vuex';
export default {
    components: {
    },
    props: {
    },
    computed: {
        ...mapGetters([]),
    },
    data() {
        return {
        };
    },
    created() {
    },
    mounted() {

    },
    watch: {

    },
    methods: {
        // 监测数据select 下拉框 大部分来自 后端枚举接口；少部分 调用特殊的接口
        ...mapActions(["GetYcysList", "GetXZQHByCode",]),
        // 遗产要素现状记录
        async getYcysData() {
            let data = await this.GetYcysList();
            const res = data && data.ResultValue;
            const transList = {};
            if (Array.isArray(res) && res.length > 0) {
                res.forEach((item) => {
                    transList[item.BM] = item.MC;
                });
            }
            this.tableGroupAttributes.snTable.columns.columnsData.find((item) =>
                ["YCYSBM"].includes(item.prop)
            ).transList = transList;
            const list = (res || []).map(({ BM: ID, MC: NAME }) => ({
                ID,
                NAME,
            }));
            let tempSelect = {
                label: "遗产要素",
                clearable: true,
                placeholder: `请选择遗产要素`,
                list,
                optionLabel: "NAME",
                optionValue: "ID",
                value: "YCYSBM",
                operateType: "search",
                isShow: true,
                width: "200px",
            };
            if (!this.tableGroupAttributes.snSearch) {
                this.tableGroupAttributes.snSearch = { selectData: [] };
            }
            if (
                !this.tableGroupAttributes.snSearch.selectData.find(
                    (ele) => ele.value === "YCYSBM"
                )
            ) {
                this.tableGroupAttributes.snSearch.selectData.push(tempSelect);
            }
        },
        // 获取村 组
        async getCountryGroup(currentItem) {
            let countryValue = ""
            let groupValue = ""
            switch (currentItem) {
                case "307": //古茶树
                    countryValue = "CM"
                    groupValue = "ZM"
                    break;
                case "90501": //民居建设;
                case "90502": //民居建设监测;
                    countryValue = "YCCZ"
                    groupValue = "YCYS"
                    break;
            }
            // 获取 村 列表
            let resultCountry = await this.GetXZQHByCode({
                code: "",
                userid: "",
                lx: "",
            });
            let listCountry = [];
            resultCountry.RESULTVALUE.map((item) => {
                listCountry.push({
                    ID: item.MC,
                    NAME: item.MC
                })
            });
            let tempSelectCountry = {
                label: "村",
                clearable: true,
                placeholder: `请选择村`,
                list: listCountry,
                optionLabel: "NAME",
                optionValue: "ID",
                value: countryValue,
                operateType: "search",
                isShow: true,
                width: "200px",
            };
            if (
                !this.tableGroupAttributes.snSearch.selectData.find(
                    (ele) => ele.value === countryValue
                )
            ) {
                this.tableGroupAttributes.snSearch.selectData.push(tempSelectCountry);
            }

            // 获取组数据
            let resultGroup = await this.GetXZQHByCode({
                code: "",
                userid: "",
                lx: 1, //0 村 1组
            });
            let listGroup = [];
            resultGroup.RESULTVALUE.map((item) => {
                listGroup.push({
                    ID: item.MC,
                    NAME: item.MC
                })
            });
            let tempSelectGroup = {
                label: "组",
                clearable: true,
                placeholder: `请选择组`,
                list: listGroup,
                optionLabel: "NAME",
                optionValue: "ID",
                value: groupValue,
                operateType: "search",
                isShow: true,
                width: "200px",
            };
            if (
                !this.tableGroupAttributes.snSearch.selectData.find(
                    (ele) => ele.value === groupValue
                )
            ) {
                this.tableGroupAttributes.snSearch.selectData.push(tempSelectGroup);
            }
        },
    },

};