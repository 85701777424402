<template>
    <div class="yjsbjl_reply_dialog">
        <div class="mainBox">
            <div class="label">回复内容</div>
            <div class="content">
                <el-input
                    v-model="dialogInfo.HFNR"
                    type="textarea"
                    maxlength="255"
                    show-word-limit
                    :autosize="{minRows: 4,maxRows: 8}">
                </el-input>
            </div>
        </div>
        <div class="btnBox">
            <el-button
                size="small"
                type="primary"
                :disabled="!dialogInfo.HFNR"
                @click="saveReply">
                回复
            </el-button>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    name: "yjsbjl_reply_dialog",
    data() {
        return {
            replyContent: '',
        }
    },
    components: {
    },
    computed: {
    },
    props: {
        dialogInfo: Object,
    },
    watch:{
    },
    methods: {
        ...mapActions([
            'saveMonitorData'
        ]),
        async saveReply() {
            let res = await this.saveMonitorData({
                dataDic: this.dialogInfo, 
                fileids: '',
                itemCode: 1205,
                metaJson: []
            });
            if (res.IsSuccess) {
                this.$message({
                    type: 'success',
                    message: '保存成功'
                });
                this.$emit('closeReplyDialog');
            }
        }
    },
    mounted() {
    }
}
</script>
<style scoped lang="scss"> 
.yjsbjl_reply_dialog{
    .mainBox{
        display: flex;
        justify-content: space-between;
        .label{
            width: 80px;
        }
        .content{
            flex: 1;
        }
    }
    .btnBox{
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }
}
</style>
