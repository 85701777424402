<template>
    <div id="advance-options-container">
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
        <el-checkbox-group v-model="checkedList" @change="handleCheckedChange">
            <el-checkbox v-for="(option,ind) in dataItems" :key="ind" :label="option">{{option.LABEL}}</el-checkbox>
        </el-checkbox-group>
    </div>
</template>
<script>
export default {
    props: {
        dataItems: {
            required: false,
            default: () => [],
        }
    },
    data() {
        return {
            emitValue: 'CODE',
            checkAll: false,
            checkedList: [],
            isIndeterminate: true
        };
    },
    methods: {
        handleCheckAllChange(val) {
            this.checkedList = val ? this.dataItems : [];
            this.isIndeterminate = false;
            const dataItems = this.dataItems.map(item => item[this.emitValue]);
            this.$emit('serchdata', val ? dataItems : []);
        },
        handleCheckedChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.dataItems.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.dataItems.length;
            this.$emit('serchdata', value.map(item => item[this.emitValue]));
        },
        reset() {
            this.checkedList = [];
            this.checkAll = false;
        },
    }
};
</script>
<style lang="scss" >
#advance-options-container{
    display: flex;
    label{
        margin: 0 10px;
        >span{
            padding:0 10px;
            &:nth-of-type(1){
               display: none;
            }
        }
        &.is-checked{
            background: rgb(97, 165, 232);
            >span{
                color: #fff;
            }
        }
    }
}
</style>
