import { mapActions } from "vuex";
// 一键上报 弹出框 start
import YjsbljReplyDialog from "./comp/daily_manage/replay_dialog"; //一键上报回复 弹出框
import YjsbljTurnErrorDialog from "./comp/daily_manage/turnError_dialog"; //一键上报 上报转异常 弹出框
import YjsbljTurnWarnDialog from "./comp/daily_manage/turnWarn_dialog"; //一键上报 异常转预警 弹出框


// 一键上报 弹出框 end
export default {
    components: {
        YjsbljReplyDialog,
        YjsbljTurnErrorDialog,
        YjsbljTurnWarnDialog,
    },
    props: {

    },
    computed: {

    },
    watch: {

    },
    data() {
        return {
            dialogReply: {
                title: "上报回复",
                dialog: false,
                class: "replyDialog",
                row: "",
            },
            dialogTurnError: {
                title: "上报转异常",
                dialog: false,
                class: "replyDialog",
                row: "",
            },
            dialogTurnWarn: {
                title: "异常转预警",
                dialog: false,
                class: "",
                row: "",
            },
        };
    },
    created() {

    },
    mounted() {
    },
    methods: {
        ...mapActions([
            "GetDxYclx",
            "GetRcxcycjlYclxList",
        ]),
        closeDialog() {
            this.dialogTurnError.dialog = false//一键上报 上报转 异常
            this.dialogTurnWarn.dialog = false//一键上报 异常 转预警
            this.getTableData();
        },
        // 获取上报类型列表App
        async getSblxAppList() {
            let result = await this.GetRcxcycjlYclxList({});
            result.forEach((item) => {
                item.id = item.ID;
                item.label = item.NAME;
                if (item.Children && item.Children.length > 0) {
                    item.children = item.Children;
                    this.handleChild(item.children);
                }
            });
            return result
        },
        handleChild(val) {
            if (val && val.length > 0) {
                val.forEach((ele) => {
                    ele.id = ele.ID;
                    ele.label = ele.NAME;
                    if (ele.Children && ele.Children.length > 0) {
                        ele.children = ele.Children;
                        this.handleChild(ele.children);
                    }
                });
                return val;
            }
        },

    }
}