<template>
    <div class="mainPart turnError">
        <el-form
            ref="formdata"
            class="demo-form-inline form_row"
            :inline="true"
            :model="formdata"
            size="small"
            label-width="100px"
            :rules="rules"
        >
            <div class="flex mainCtn">
                <div class="mainItem">
                    <el-form-item label="异常类型" prop="yclx">
                        <div class="treePart">
                            <div
                                class="treeLabel pointer"
                                @click="treeLabelState = !treeLabelState"
                            >
                                {{ formdata.yclxName }}
                            </div>
                            <div
                                class="warpTree scrollbar"
                                v-if="treeLabelState"
                            >
                                <el-tree
                                    :data="yclxList"
                                    :props="defaultProps"
                                    @node-click="handleNodeClick"
                                ></el-tree>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="异常事件" prop="ycsj">
                        <el-input
                            v-model="formdata.ycsj"
                            placeholder="请输入异常事件"
                            :maxlength="50"
                            type="textarea"
                            style="width: 300px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="事件描述" prop="sjms">
                        <el-input
                            v-model="formdata.sjms"
                            placeholder="请输入事件描述"
                            :maxlength="50"
                            type="textarea"
                            style="width: 300px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="评估" prop="pg">
                        <el-select
                            v-model="formdata.pg"
                            placeholder="请选择评估"
                            filterable
                            style="width: 300px"
                            clearable
                        >
                            <el-option
                                v-for="(item, index) in pgList"
                                :key="index"
                                :label="item.LABEL"
                                :value="item.CODE"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="mainItem">
                    <el-form-item label="发生位置" prop="fswz">
                        <el-input
                            v-model="formdata.fswz"
                            placeholder="请输入事件描述"
                            disabled
                            style="width: 300px"
                            type="textarea"
                            :row="3"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="巡查员" prop="xcy">
                        <el-input
                            style="width: 300px"
                            v-model="formdata.xcy"
                            disabled
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="巡查日期" prop="xcrq">
                        <el-input
                            style="width: 300px"
                            v-model="formdata.xcrq"
                            disabled
                        ></el-input>
                    </el-form-item>
                </div>
            </div>
            <el-form-item label="异常照片" prop="">
                <div class="imgPart flex flex-wrap scrollbar">
                    <el-image
                        v-viewer
                        v-for="(item, index) in imgList"
                        :key="index"
                        :src="item.CCLJ"
                        fit="cover"
                        class="pointer"
                        style="width: 150px; height: 150px; margin-right: 10px"
                    >
                        <div slot="error" style="width: 100%; height: 100%">
                            <SnNoData typeNum="1" width="100px"></SnNoData>
                        </div>
                    </el-image>
                </div>
                <div class="flex flex-wrap">
                    <audio
                        v-for="(item, index) in mp3List"
                        :key="index"
                        style="margin-right: 10px"
                        :src="item.CCLJ"
                        controls
                    ></audio>
                </div>
                <div class="flex flex-wrap" style="margin-top: 10px">
                    <video
                        v-for="(item, index) in mp4List"
                        :key="index"
                        :src="item.CCLJ"
                        controls
                        style="width: 150px; height: 150px; margin-right: 10px"
                    ></video>
                </div>
            </el-form-item>
            <div class="flex-end">
                <el-button
                    type="primary"
                    class="submitBtn"
                    round
                    @click="onSubmit()"
                    >上报转异常</el-button
                >
                <el-button
                    type="danger"
                    class="cancelBtn"
                    round
                    @click="cancel()"
                    >取消</el-button
                >
            </div>
        </el-form>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "",
    data() {
        return {
            treeLabelState: false,
            defaultProps: {
                children: "Children",
                label: "NAME",
            },
            formdata: {
                yclxId: "", //异常类型
                yclxName: "请选择异常类型", //异常类型
                ycsj: "现状说明", //异常事件
                sjms: "现状说明", //事件描述
                pg: "", //评估
                fswz: "", //发生位置（读取展示）
                xcy: localStorage.userRealName, //巡查员（给当前用户，只看不修改）
                xcrq: "", //巡查日期（读取展示，只看不修改）
            },
            replyContent: "",
            yclxList: [], //异常类型列表
            pgList: [], //评估列表
            rules: {
                yclx: [
                    {
                        required: true,
                        message: "请选择异常类型",
                        trigger: "change",
                    },
                ],
                pg: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
            },
            mp3List: [],
            mp4List: [],
            imgList: [],
        };
    },
    components: {},
    computed: {},
    props: {
        dialogInfo: Object,
    },
    watch: {
        dialogInfo: {
            immediate: true,
            handler() {
                console.log(
                    "%c 🍮 dialogInfo: ",
                    "font-size:20px;background-color: #93C0A4;color:#fff;",
                    this.dialogInfo
                );
                this.formdata.fxId = this.dialogInfo.ID;
                this.formdata.yclx = this.dialogInfo.YCLX; //异常类型
                this.formdata.ycsj = this.dialogInfo.SJMS
                    ? this.dialogInfo.SJMS
                    : "现状说明"; //异常事件
                this.formdata.sjms = this.dialogInfo.SJMS
                    ? this.dialogInfo.SJMS
                    : "现状说明"; //事件描述
                this.formdata.pg = this.dialogInfo.PG; //评估
                this.formdata.fswz = this.dialogInfo.YCFSWZ; //发生位置
                this.formdata.xcy = localStorage.userRealName; //巡查员
                this.formdata.xcrq = this.dialogInfo.SBSJ; //巡查日期

                this.mp3List = [];
                this.mp4List = [];
                this.imgList = [];
                this.dialogInfo.PIC.map((item) => {
                    let urlList = item.CCLJ.split(".");
                    let dw = urlList[urlList.length - 1];
                    switch (dw) {
                        case "mp3":
                            this.mp3List.push(item);
                            break;
                        case "mp4":
                            this.mp4List.push(item);
                            break;
                        default:
                            this.imgList.push(item);
                            break;
                    }
                });
            },
        },
    },
    mounted() {
        this.getEnums();
        this.getYclxList();
    },
    methods: {
        ...mapActions([
            "WdfxToYcjl",
            "getAllEnumInfobytableID",
            "GetRcxcycjlYclxList",
        ]),
        async onSubmit() {
            if (!this.formdata.yclxId) {
                this.common.showMsg("请选择异常类型", "warning");
                return;
            }
            let ycjlInfo = {
                yclx: this.formdata.yclxId, //异常类型
                ycsj: this.formdata.ycsj, //异常事件
                sjms: this.formdata.sjms, //事件描述
                pg: this.formdata.pg, //评估
            };
            let res = await this.WdfxToYcjl({
                ycjlInfo: JSON.stringify(ycjlInfo),
                fxId: this.formdata.fxId,
                userId: localStorage.userId,
            });
            if (res.IsSuccess) {
                this.common.showMsg("保存成功", "success");
                this.$emit("closeDialog");
            }
        },
        cancel() {
            this.$emit("closeDialog");
        },
        async getEnums() {
            const res = await this.getAllEnumInfobytableID({
                bid: 1205,
            });
            res.map((item) => {
                if (item.COLUMNID == "PG") {
                    this.pgList = item.dataItems;
                }
            });
        },
        // 异常类型数组
        async getYclxList() {
            const res = await this.GetRcxcycjlYclxList({});
            this.yclxList = res;
        },
        handleNodeClick(data) {
            console.log(data);
            if (!data.Children) {
                this.formdata.yclxId = data.ID;
                this.formdata.yclxName = data.NAME;
                this.treeLabelState = !this.treeLabelState;
            }
        },
    },
};
</script>
<style scoped lang="scss">
.mainPart {
    width: 100%;
    .mainCtn {
        width: 100%;
        .mainItem {
            width: 50%;
            .treePart {
                width: 300px;
                height: 40px;
                position: relative;
                .treeLabel {
                    width: 100%;
                    height: 100%;
                    border-color: #e4e7ed;
                    color: #606266;
                    border: 1px solid #dcdfe6;
                    border-radius: 4px;
                    padding: 4px 14px;
                    font-size: 13.3px;
                }
                .warpTree {
                    width: 100%;
                    height: 200px;
                    position: absolute;
                    left: 0;
                    top: 50px;
                    z-index: 10;
                    border: 1px solid #e4e7ed;
                    border-radius: 4px;
                    background-color: #fff;
                    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
                    box-sizing: border-box;
                    margin: 5px 0;
                }
            }
        }
    }
    .imgPart {
        width: 800px;
        max-height: 200px;
        .imgItem {
            width: 100px;
            height: 100px;
            margin-right: 4px;
            margin-bottom: 4px;
        }
    }
}
</style>
